export const events2024 = {
  January: [
    {
      day: 1,
      name: "events.january.eventName1",
      date: "01 January 2024",
      duration: "60 minutes",
    },
    {
      day: 2,
      name: "events.january.eventName2",
      date: "02 January 2024",
      duration: "60 minutes",
    },
    {
      day: 3,
      name: "events.january.eventName3",
      date: "03 January 2024",
      duration: "60 minutes",
    },
    {
      day: 4,
      name: "events.january.eventName4",
      date: "05 January 2024",
      duration: "60 minutes",
    },
    {
      day: 5,
      name: "events.january.eventName5",
      date: "01 January 2024",
      duration: "60 minutes",
    },
    {
      day: 6,
      name: "events.january.eventName6",
      date: "06 January 2024",
      duration: "60 minutes",
    },
    {
      day: 7,
      name: "events.january.eventName7",
      date: "07 January 2024",
      duration: "60 minutes",
    },
    {
      day: 8,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 9,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 10,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 11,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 12,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 13,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 14,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 15,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 16,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 17,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 18,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 19,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 20,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 21,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 22,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 23,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 24,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 25,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 26,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 27,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 28,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 29,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 30,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
    {
      day: 31,
      name: "events.january.eventName8",
      date: "08 January 2024",
      duration: "60 minutes",
    },
  ],
  February: [
    {
      day: 1,
      name: "events.february.eventName1",
      date: "01 February 2024",
      duration: "60 minutes",
    },
    {
      day: 2,
      name: "events.february.eventName2",
      date: "02 February 2024",
      duration: "60 minutes",
    },
    {
      day: 3,
      name: "events.february.eventName3",
      date: "03 February 2024",
      duration: "60 minutes",
    },
    {
      day: 4,
      name: "events.february.eventName4",
      date: "04 February 2024",
      duration: "60 minutes",
    },
    {
      day: 5,
      name: "events.february.eventName5",
      date: "05 February 2024",
      duration: "60 minutes",
    },
    {
      day: 6,
      name: "events.february.eventName6",
      date: "06 February 2024",
      duration: "60 minutes",
    },
    {
      day: 7,
      name: "events.february.eventName7",
      date: "07 February 2024",
      duration: "60 minutes",
    },
    {
      day: 8,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 9,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 10,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 11,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 12,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 13,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 14,
      name: "events.february.eventName1",
      date: "01 February 2024",
      duration: "60 minutes",
    },
    {
      day: 15,
      name: "events.february.eventName2",
      date: "02 February 2024",
      duration: "60 minutes",
    },
    {
      day: 16,
      name: "events.february.eventName3",
      date: "03 February 2024",
      duration: "60 minutes",
    },
    {
      day: 17,
      name: "events.february.eventName4",
      date: "04 February 2024",
      duration: "60 minutes",
    },
    {
      day: 18,
      name: "events.february.eventName5",
      date: "05 February 2024",
      duration: "60 minutes",
    },
    {
      day: 19,
      name: "events.february.eventName6",
      date: "06 February 2024",
      duration: "60 minutes",
    },
    {
      day: 20,
      name: "events.february.eventName7",
      date: "07 February 2024",
      duration: "60 minutes",
    },
    {
      day: 21,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 22,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 23,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 24,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 25,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 26,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 27,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 28,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 29,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
    {
      day: 30,
      name: "events.february.eventName8",
      date: "08 February 2024",
      duration: "60 minutes",
    },
  ],
  // Add more months as needed
  // March:[],
  // April:[],
  // May:[],
  // June:[],
  // July:[],
  // August:[],
  // September:[],
  // October:[],
  // November:[],
  // December:[],
};

export const updatedEvent2024=[
  {
    month:"January",
    eventList:[
      "events.january.eventName1",
      "events.january.eventName2",
      "events.january.eventName3",
      "events.january.eventName4",
      "events.january.eventName5",
      "events.january.eventName6",
      "events.january.eventName7",
      "events.january.eventName8",
    ]
  },
  {
    month:"February",
    eventList:[
      "events.february.eventName1",
      "events.february.eventName2",
      "events.february.eventName3",
      "events.february.eventName4",
      "events.february.eventName5",
      "events.february.eventName6",
      "events.february.eventName7",
      "events.february.eventName8",
    ]
  },
  {
    month:"March",
    eventList:[
      "events.march.eventName1",
      "events.march.eventName2",
      "events.march.eventName3",
      "events.march.eventName4",
      "events.march.eventName5",
      "events.march.eventName6",
      "events.march.eventName7",
      "events.march.eventName8",
    ]
  },
  {
    month:"April",
    eventList:[
      "events.april.eventName1",
      "events.april.eventName2",
      "events.april.eventName3",
      "events.april.eventName4",
      "events.april.eventName5",
      "events.april.eventName6",
      "events.april.eventName7",
      "events.april.eventName8",
    ]
  },
  {
    month:"May",
    eventList:[
      "events.may.eventName1",
      "events.may.eventName2",
      "events.may.eventName3",
      "events.may.eventName4",
      "events.may.eventName5",
      "events.may.eventName6",
      "events.may.eventName7",
      "events.may.eventName8",
    ]
  },
  {
    month:"June",
    eventList:[
      "events.june.eventName1",
      "events.june.eventName2",
      "events.june.eventName3",
      "events.june.eventName4",
      "events.june.eventName5",
      "events.june.eventName6",
      "events.june.eventName7",
      "events.june.eventName8",
    ]
  },
  {
    month:"July",
    eventList:[
      "events.july.eventName1",
      "events.july.eventName2",
      "events.july.eventName3",
      "events.july.eventName4",
      "events.july.eventName5",
      "events.july.eventName6",
      "events.july.eventName7",
      "events.july.eventName8",
    ]
  },
  {
    month:"August",
    eventList:[
      "events.august.eventName1",
      "events.august.eventName2",
      "events.august.eventName3",
      "events.august.eventName4",
      "events.august.eventName5",
      "events.august.eventName6",
      "events.august.eventName7",
      "events.august.eventName8",
    ]
  },
  {
    month:"September",
    eventList:[
      "events.september.eventName1",
      "events.september.eventName2",
      "events.september.eventName3",
      "events.september.eventName4",
      "events.september.eventName5",
      "events.september.eventName6",
      "events.september.eventName7",
      "events.september.eventName8",
    ]
  },
  {
    month:"October",
    eventList:[
      "events.october.eventName1",
      "events.october.eventName2",
      "events.october.eventName3",
      "events.october.eventName4",
      "events.october.eventName5",
      "events.october.eventName6",
      "events.october.eventName7",
      "events.october.eventName8",
    ]
  },
  {
    month:"November",
    eventList:[
      "events.november.eventName1",
      "events.november.eventName2",
      "events.november.eventName3",
      "events.november.eventName4",
      "events.november.eventName5",
      "events.november.eventName6",
      "events.november.eventName7",
      "events.november.eventName8",
    ]
  },
  {
    month:"December",
    eventList:[
      "events.december.eventName1",
      "events.december.eventName2",
      "events.december.eventName3",
      "events.december.eventName4",
      "events.december.eventName5",
      "events.december.eventName6",
      "events.december.eventName7",
      "events.december.eventName8",
    ]
  }
]