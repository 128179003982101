import React from "react";
import { FaAnglesRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import "./aboutus.css";
import { useTranslation } from "react-i18next";

const OurTeam = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/"><h3>{t("Home")}</h3></Link>
          <FaAnglesRight size={14} />
          <span>{t("Our Team")}</span>
        </div>
      </div>
      <div className="content our-team">
        <div className="container-wrapper">
          <h1 className="bold f-40 color-primary">{t("Board_of_Directors")}</h1>
          <ul>
          <li>
              <div className="image-wrapper">
                <img src="/assets/images/image1.jpg" alt="team" />
              </div>
              {/* <h4 className="f-16 bold">Engineer</h4> */}
              <h3 className="medium f-14">{t("Team.name1")}</h3>
              {/* <h3 className="f-16 bold">Excellency Eng.Abdulrahman Abdulmohsen A. AlFadley</h3> */}
              <p className="f-14 medium color-primary">{t("Team.role1")}</p>
              <h4 className="f-14 bold color-primary">{t("Team.board1")}</h4>
            </li>
          </ul>
          <ul>
            
            <li>
              <div className="image-wrapper">
                <img src="/assets/images/image4.jpg" alt="team" />
              </div>
              {/* <h4 className="f-16 bold">Doctor</h4> */}
              <h3 className="medium f-14">{t("Team.name4")}</h3>
              <p className="f-14 medium color-primary">{t("Team.role4")}</p>
              <h4 className="f-14 bold color-primary">{t("Team.board2")}</h4>
              {/* <div className="social-links">
                <Link>
                  <img src="/assets/images/twitter-blue.png" alt="twitter" />
                </Link>
                <Link>
                  <img src="/assets/images/facebook-blue.png" alt="facebook" />
                </Link>
                <Link>
                  <img
                    src="/assets/images/instagram-blue.png"
                    alt="instagram"
                  />
                </Link>
              </div> */}
            </li>
            <li>
              <div className="image-wrapper">
                <img src="/assets/images/image3.jpg" alt="team" />
              </div>
              {/* <h4 className="f-16 bold">Engineer</h4> */}
              <h3 className="medium f-14">{t("Team.name3")}</h3>
              <p className="f-14 medium color-primary">{t("Team.role3")}</p>
              <h4 className="f-14 bold color-primary">{t("Team.board2")}</h4>
              {/* <div className="social-links">
                <Link>
                  <img src="/assets/images/twitter-blue.png" alt="twitter" />
                </Link>
                <Link>
                  <img src="/assets/images/facebook-blue.png" alt="facebook" />
                </Link>
                <Link>
                  <img
                    src="/assets/images/instagram-blue.png"
                    alt="instagram"
                  />
                </Link>
              </div> */}
            </li>
            <li>
              <div className="image-wrapper">
                <img src="/assets/images/image2.jpg" alt="team" />
              </div>
              {/* <h4 className="f-16 bold">Mr.</h4> */}
              <h3 className="medium f-14">{t("Team.name2")}</h3>
              <p className="f-14 medium color-primary">{t("Team.role2")}</p>
              <h4 className="f-14 bold color-primary">{t("Team.board2")}</h4>
              {/* <div className="social-links">
                <Link>
                  <img src="/assets/images/twitter-blue.png" alt="twitter" />
                </Link>
                <Link>
                  <img src="/assets/images/facebook-blue.png" alt="facebook" />
                </Link>
                <Link>
                  <img
                    src="/assets/images/instagram-blue.png"
                    alt="instagram"
                  />
                </Link>
              </div> */}
            </li>
            <li>
              <div className="image-wrapper">
                <img src="/assets/images/image7.jpeg" alt="team" />
              </div>
              {/* <h4 className="f-16 bold">Mr.</h4> */}
              <h3 className="medium f-14">{t("Team.name7")}</h3>
              <p className="f-14 medium color-primary">{t("Team.role7")}</p>
              <h4 className="f-14 bold color-primary">{t("Team.board2")}</h4>
              {/* <div className="social-links">
                <Link>
                  <img src="/assets/images/twitter-blue.png" alt="twitter" />
                </Link>
                <Link>
                  <img src="/assets/images/facebook-blue.png" alt="facebook" />
                </Link>
                <Link>
                  <img
                    src="/assets/images/instagram-blue.png"
                    alt="instagram"
                  />
                </Link>
              </div> */}
            </li>

            <li>
              <div className="image-wrapper">
                <img src="/assets/images/image6.jpg" alt="team" />
              </div>
              {/* <h4 className="f-16 bold">Doctor</h4> */}
              <h3 className="medium f-14">{t("Team.name6")}</h3>
              <p className="f-14 medium color-primary">{t("Team.role6")}</p>
              <h4 className="f-14 bold color-primary">{t("Team.board2")}</h4>
              {/* <div className="social-links">
                <Link>
                  <img src="/assets/images/twitter-blue.png" alt="twitter" />
                </Link>
                <Link>
                  <img src="/assets/images/facebook-blue.png" alt="facebook" />
                </Link>
                <Link>
                  <img
                    src="/assets/images/instagram-blue.png"
                    alt="instagram"
                  />
                </Link>
              </div> */}
            </li>
            <li>
              <div className="image-wrapper">
                <img src="/assets/images/image5.jpg" alt="team" />
              </div>
              {/* <h4 className="f-16 bold">Engineer</h4> */}
              <h3 className="medium f-14">{t("Team.name5")}</h3>
              <p className="f-14 medium color-primary">{t("Team.role5")}</p>
              <h4 className="f-14 bold color-primary">{t("Team.board2")}</h4>
              {/* <div className="social-links">
                <Link>
                  <img src="/assets/images/twitter-blue.png" alt="twitter" />
                </Link>
                <Link>
                  <img src="/assets/images/facebook-blue.png" alt="facebook" />
                </Link>
                <Link>
                  <img
                    src="/assets/images/instagram-blue.png"
                    alt="instagram"
                  />
                </Link>
              </div> */}
            </li>
            <li>
              <div className="image-wrapper">
                <img src="/assets/images/image10.jpg" alt="team" />
              </div>
              {/* <h4 className="f-16 bold">Mr.</h4> */}
              <h3 className="medium f-14">{t("Team.name10")}</h3>
              <p className="f-14 medium color-primary">{t("Team.role10")}</p>
              <h4 className="f-14 bold color-primary">{t("Team.board2")}</h4>
              {/* <div className="social-links">
                <Link>
                  <img src="/assets/images/twitter-blue.png" alt="twitter" />
                </Link>
                <Link>
                  <img src="/assets/images/facebook-blue.png" alt="facebook" />
                </Link>
                <Link>
                  <img
                    src="/assets/images/instagram-blue.png"
                    alt="instagram"
                  />
                </Link>
              </div> */}
            </li>
            <li>
              <div className="image-wrapper">
                <img src="/assets/images/image9.jpeg" alt="team" />
              </div>
              {/* <h4 className="f-16 bold">Mr.</h4> */}
              <h3 className="medium f-14">{t("Team.name9")}</h3>
              <p className="f-14 medium color-primary">{t("Team.role9")}</p>
              <h4 className="f-14 bold color-primary">{t("Team.board2")}</h4>
              {/* <div className="social-links">
                <Link>
                  <img src="/assets/images/twitter-blue.png" alt="twitter" />
                </Link>
                <Link>
                  <img src="/assets/images/facebook-blue.png" alt="facebook" />
                </Link>
                <Link>
                  <img
                    src="/assets/images/instagram-blue.png"
                    alt="instagram"
                  />
                </Link>
              </div> */}
            </li>
            <li>
              <div className="image-wrapper">
                <img src="/assets/images/image8.jpg" alt="team" />
              </div>
              {/* <h4 className="f-16 bold">Doctor</h4> */}
              <h3 className="medium f-14">{t("Team.name8")}</h3>
              <p className="f-14 medium color-primary">{t("Team.role8")}</p>
              <h4 className="f-14 bold color-primary">{t("Team.board2")}</h4>
              {/* <div className="social-links">
                <Link>
                  <img src="/assets/images/twitter-blue.png" alt="twitter" />
                </Link>
                <Link>
                  <img src="/assets/images/facebook-blue.png" alt="facebook" />
                </Link>
                <Link>
                  <img
                    src="/assets/images/instagram-blue.png"
                    alt="instagram"
                  />
                </Link>
              </div> */}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
