import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { FaAnglesRight, FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import "./media.css";
import { newsContent } from "../../content/news";
import { useTranslation } from "react-i18next";

const News = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openNews, setOpenNews] = useState([]);
  const pages = [1, 2, 3, 4, 5];
  const [page, setPage] = useState(1);
  const [expandedNews, setExpandedNews] = useState({});
  const itemsPerPage = 6;
  const paginatedNews = newsContent
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .slice((page - 1) * itemsPerPage, page * itemsPerPage);
  const parseDateString = (dateString) => {
    const dateObject = new Date(dateString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthIndex = dateObject.getMonth();
    const monthName = monthNames[monthIndex];
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();
    return day.toString() + " " + t(`${monthName}`) + " " + year.toString();
  };
  const toggleNewsExpand = (title) => {
    setExpandedNews((prevExpandedNews) => ({
      ...prevExpandedNews,
      [title]: !prevExpandedNews[title],
    }));
  };

  const pageChangeHandlder = useCallback(
    (isIncrement) => {
      if (!isIncrement) {
        if (page - 1 < 1) {
          setPage(5);
        } else {
          setPage(page - 1);
        }
      } else {
        if (page + 1 > 5) {
          setPage(1);
        } else {
          setPage(page + 1);
        }
      }
    },
    [page, expandedNews]
  );

  const openNewsHandler = useCallback(
    (index) => {
      const temp = [...openNews];
      const newsIndex = temp.indexOf(index);
      if (newsIndex !== -1) {
        temp.splice(newsIndex, 1);
      } else {
        // console.log("HIT");
        temp.push(index);
      }
      setOpenNews([...temp]);
    },
    [openNews]
  );
  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/"><h3>{t("Home")}</h3></Link>
          <FaAnglesRight size={14} />
          <Link to="/media-center/faqs"><h3>{t("mediaCenter")}</h3></Link>
          <FaAnglesRight size={14} />
          <span>{t("News")}</span>
        </div>
      </div>
      <div className="content media-news">
        <div className="container-wrapper">
          <h1 className="bold f-40 color-primary">{t("Home_News")}</h1>
          <p className="medium f-14 color-gray">
            {t("Media_center_news_Enhance")}
          </p>
          <ul>
            {paginatedNews?.map((item, idx) => (
              <li key={idx}>
                <img src={`/assets/images/${item.image}`} alt="news" />
                {/* <span className='f-14 medium'>{t(`${item.date}`)}</span> */}
                <span className="f-14 medium" style={{fontFamily:"arial", fontWeight:"bold"}}>
                  {parseDateString(item.date)}
                  {/* <p style={{fondFamily:"Arial", fondWeight:"bold"}}>{item.date}</p> */}
                </span>
                <h2 className={`bold f-28`}>{t(`${item.title}`)}</h2>
                <p
                  className={
                    openNews.indexOf(idx) !== -1
                      ? `medium f-14 open`
                      : `medium f-14`
                  }
                >
                  {t(item.news)}
                </p>
                {/* <div className={`news-content ${expandedNews[item.title] ? 'expanded' : 'collapsed'}`}>
                  {item.news}
                </div> */}
                <button
                  className="primary white"
                  onClick={() => navigate(`/media-center/news/${item.id}`)}
                // onClick={() => openNewsHandler(idx)}
                >
                  {/* {t("Read More")} */}
                  {t(openNews.indexOf(idx) !== -1 ? "Show Less" : "Read More")}
                </button>
              </li>
            ))}
          </ul>
          <div className="pagination">
            <button onClick={() => pageChangeHandlder(false)}>
              <div className="pagination_arrow"><FaChevronLeft size={14} /></div>
            </button>
            {pages.map((item) => (
              <button
                onClick={() => setPage(item)}
                className={page === item ? "bold f-14 active" : "bold f-14"}
                style={{fontFamily:"arial"}}
              >
                {item}
              </button>
            ))}
            <button onClick={() => pageChangeHandlder(true)}>
              <div className="pagination_arrow"><FaChevronRight size={14} /></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
