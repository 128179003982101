import React, { useState } from 'react'
import { FaEye } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

function LaboratoriesTable() {
  const [selectedCity, setSelectedCity] = useState(null)
  const [tempValue, setTempValue] = useState(null)

  const { t } = useTranslation()
  return (
    <div id='laboratories-table'>
      <div style={styles.dropdown} className='approve__dropdown'>
        <select
          style={styles.dropdownSelect}
          onChange={(e) => {
            setTempValue(e.target.value)
          }}
          value={tempValue}
        >
          <option value={null} disabled selected hidden>{t("Choose City")}</option>
          {cities.map((city) => (
            <option value={city} className='medium f-18'>{t(city)}</option>
          ))}
        </select>
        <button
          className='medium f-14'
          onClick={() => {
            setSelectedCity(tempValue)
          }}
          style={styles.btn}
        >
          {t('search')}
        </button>
      </div>
      <div className='table'>
        <table style={styles.table} className='laboratories__table'>
          <thead style={styles.thead} className='laboratories__thead'>
            <tr>
              {header.map((ob) => (
                <th className='medium f-14' id={ob.id} style={styles.thtd}>
                  {t(ob.name)}
                </th>
              ))}
            </tr>
          </thead>
          {rows
            .filter((ob) => {
              // console.log(selectedCity)
              if (!selectedCity || selectedCity === 'Choose City') {
                return true
              }
              if (!selectedCity || selectedCity === 'All-Cities') {
                return true
              }
              return ob.location == selectedCity
            })
            .map((ob) => (
              <tr key={ob.key}>
                <td className='medium f-14' style={styles.thtd}>
                  {ob.id}
                </td>
                <td className='medium f-14' style={styles.thtd}>
                  {t(ob.labName)}
                </td>
                <td className='medium f-14' style={styles.thtd}>
                  {t(ob.location)}
                </td>
                <td
                  className='medium f-14'
                  style={{
                    ...styles.thtd,
                    color: '#9bd2a5',
                    textAlign: 'center',
                    cursor: 'pointer',
                    alignItems:"center",
                    margin:"1rem",
                    gap: '1rem',
                  }}
                  onClick={() => window.open(ob.locationUrl)}
                >
                  <div style={styles.eyeIcon}>
                  <FaEye size={16} />
                  <span className='medium f-14'>{t('see_website')}</span>
                  </div>
                </td>
              </tr>
            ))}
        </table>
      </div>
    </div>
  )
}

export default LaboratoriesTable

const rows = [
  {
    id: 1,
    labName: 'Qassim University Laboratory',
    location: 'Al-Qassim',
    locationUrl:
      'https://cavm.qu.edu.sa/content/pages/1156/%D9%88%D8%AD%D8%AF%D8%A9-%D8%AE%D8%AF%D9%85%D8%A9-%D8%A7%D9%84%D9%85%D8%AC%D8%AA%D9%85%D8%B9-%D9%88%D8%A7%D9%84%D8%A8%D9%8A%D8%A6%D8%A9',
  },
  {
    id: 2,
    labName: 'King Saud University Laboratory',
    location: 'Riyadh',
    locationUrl: 'https://sciences.ksu.edu.sa/ar/node/8559',
  },
  {
    id: 3,
    labName: 'idac',
    location: 'Al-Kharj',
    locationUrl: 'https://www.idac.com.sa/',
  },
  {
    id: 4,
    labName: 'Saudi Ajal',
    location: 'Riyadh',
    locationUrl: 'https://www.saudiajal.com/',
  },
  {
    id: 5,
    labName: 'Danat Tuwaiq Laboratory',
    location: 'Riyadh',
    locationUrl: 'http://danat.sa/', // need link
  },
  {
    id: 6,
    labName: 'Exova',
    location: 'Dammam',
    locationUrl: 'https://www.exova.com/',
  },
]

const header = [
  { name: 'ID', id: 'id' },
  { name: 'Lab Name', id: 'labName' },
  { name: 'City', id: 'location' },
  { name: 'LocationLink', id: 'locationUrl' },
]

const cities = ['All-Cities', 'Al-Qassim', 'Riyadh', 'Al-Kharj', 'Dammam']

const styles = {
  btn: {
    backgroundColor: '#1e495c',
    color: '#fff',
    textAlign: 'center',
    height: '2.75rem',
    borderRadius: '8px',
    width: '10rem',
  },
  dropdownSelect: { width: '15rem', border: '1px solid #1e495c' },
  dropdown: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '1rem',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  thtd: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '1rem 2rem',
  },
  thead: { backgroundColor: '#1e495c', color: 'white' },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
  },
  eyeIcon:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    gap:"1rem"
  },

  '@media screen and (min-width: 300px) and (max-width: 576px)': {
    dropdown: {
      flexDirection: 'column', // Change direction to column for smaller screens
      gap: '0.5rem', // Adjust the gap between elements
      marginBottom: '0.5rem', // Adjust the margin bottom
    },
    dropdownSelect: {
      width: '100%', // Make the select element full width
    },
    btn: {
      width: '100%', // Make the button full width
    },
    thtd: {
      fontSize: '12px', // Adjust font size for smaller screens
      padding: '0.75rem 1rem',
      flexDirection:"column"
    },
  },
}
