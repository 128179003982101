import React, { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Routes from "../Routes/Routes";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: "ar",
    fallbackLng: "en",
    whitelist: ["en", "ar"],
    debug: true, 
    detection: {
      order: ["localStorage", "htmlTag", "path", "cookie", "subdomain"],
      caches: ["localStorage"],
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/assets/lang/common/{{lng}}.json",
    },
  });
// import Backend from "i18next-xhr-backend";

const Root = ({ language, i18nextLng }) => {
  useEffect(() => {
    document.body.dir = language === "ARABIC" ? "rtl" : "";
    i18next.changeLanguage(language === "ARABIC" ? "ar" : "en");
  }, [language]);

  return (
    <div
      className={
        language === "ARABIC" ? "body-container arabic" : "body-container"
      }
    >
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Navbar />
          <Routes />
          <Footer />
        </Suspense>
      </BrowserRouter>
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language,
  i18nextLng: state.i18nextLng,
});

export default connect(mapStateToProps)(Root);
