import React, { useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { FaAnglesRight, FaArrowRight } from "react-icons/fa6";
import "./media.css";
import { newsContent, newsTopics } from "../../content/news";
import { useTranslation } from "react-i18next";

const NewsDetail = () => {
  const { t } = useTranslation();
  let paramId = useParams().id;
  let oneNews = newsContent.find((item) => item.id === Number(paramId));

  const parseDateString = (dateString) => {
    const dateObject = new Date(dateString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthIndex = dateObject.getMonth();
    const monthName = monthNames[monthIndex];
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();
    return day.toString() + " " + t(`${monthName}`) + " " + year.toString();
  };
  const NewsItem = ({ item }) => {
    const [showFull, setShowFull] = useState(false);
    const toggleShowFull = () => {
      setShowFull((prev) => !prev);
    };

    return (
      <li className={`${showFull ? "expanded" : "collapsed"}`}>
        <div className="rounded-image-container">
          <img
            src={`/assets/images/${item.image}`}
            alt="last-news1"
            id="lastNews-image"
          />
        </div>
        <h3 className="bold medium f-14">
          {t(`${item.title}`)}
        </h3>
        <span className="medium f-14">
          <img src="/assets/images/calendar.png" alt="calendar" />
          {t(`${item.date}`)}
        </span>
        <p className="medium f-14">{t(item.news)}</p>
        {item.title.split(" ").length > 10 && item.news.length > 150 && (
          <button className="bold" onClick={toggleShowFull}>
            {t(showFull ? "Show Less" : "Read More")}
            <FaArrowRight size={16} />
          </button>
        )}
        <Link to={`/media-center/news/${item.id}`}>
          <button className="bold">
            {t("Read More")}
            <FaArrowRight size={16} />
          </button>
        </Link>
      </li>
    );
  };

  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/">
            <h3>{t("Home")}</h3>
          </Link>
          <FaAnglesRight size={14} />
          <Link to="/media-center/news">
            <h3>{t("mediaCenter")}</h3>
          </Link>
          <FaAnglesRight size={14} />
          <span>{t("News")}</span>
        </div>
      </div>
      <div className="content-news-detail container-wrapper">
        <div className="detailed-news container-wrapper">
          <div className="heading">
            <div className="bold f-28 color-primary">{t("latest-news")}</div>
            <div className="bold medium f-14">{t(`${oneNews.title}`)}</div>
          </div>
          <div className="image medium f-14">
            <img src={`/assets/images/${oneNews.image}`} alt="news" />
          </div>
          <div className="date medium f-14">
            <div className="calender-icon">
              <img src="/assets/images/calendar.png" alt="" />
            </div>
            <div className="date">{t(`${parseDateString(oneNews.date)}`)}</div>
          </div>
          <div className="medium f-14">{t(`${oneNews.news}`)}</div>
        </div>
        <div className="related-topics">
          <div className="bold f-28 color-primary">
            {t("Related topics")}
          </div>
          <div className="other-topics">
            <ul>
              {newsTopics.map((item) => (
                <li>
                  <div className="medium f-14">{t(`${item.title}`)}</div>
                  <div className="calender-icon">
                    <img src="/assets/images/calendar.png" alt="" />
                    <div className="date medium f-14">{t(`${parseDateString(item.date)}`)}</div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="calender-icon"></div>
            <div className="date"></div>
          </div>
        </div>
      </div>
      <div className="latest-news container-wrapper">
        <section className="last-news container-wrapper">
          <h2 className="bold f-28 color-primary">{t("latest-news")}</h2>
          <ul>
            {newsContent
              .sort(
                (a, b) =>
                  new Date(b.date).getTime() - new Date(a.date).getTime()
              )
              .slice(1, 4)
              .map((item, index) => (
                <Link to={`/media-center/news/${item.id}`}>
                  <NewsItem key={index} item={item} />
                </Link>
              ))}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default NewsDetail;
