import React from "react";
import { Link } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";
import "./media.css";
import {reports} from "../../content/report";
import { useTranslation } from "react-i18next";

const Reports = () => {
  const {t} = useTranslation();
  const downloadReport = (filePath) => {
    const pdfUrl = `/assets/pdf/reports/${filePath}`;
    const fileName = pdfUrl.substring(pdfUrl.lastIndexOf('/') + 1);
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/"><h3>{t("Home")}</h3></Link>
          <FaAnglesRight size={14} />
          <Link to="/media-center/faqs"><h3>{t("mediaCenter")}</h3></Link>
          <FaAnglesRight size={14} />
          <span>{t("Reports")}</span>
        </div>
      </div>
      <div className="content media-reports">
        <div className="container-wrapper">
          <h1 className="bold f-40 color-primary">{t("Reports")}</h1>
          <p className="medium f-14 color-gray">
            {t("Enhance_your")}
          </p>
          <ul>
            {/* <li>
              <div className="image-wrapper">
                <img src="/assets/images/report.png" alt="report" />
              </div>
              <span className="color-primary medium f-20">
              General report</span>
              <span className="color-primary bold f-20">2020</span>
              <div>
                <button className="primary outline">DETAILS</button>
                <button className="primary">DOWNLOAD</button>
              </div>
            </li>
            <li>
              <div className="image-wrapper">
                <img src="/assets/images/report.png" alt="report" />
              </div>
              <span className="color-primary medium f-20">General report</span>
              <span className="color-primary bold f-20">2021</span>
              <div>
                <button className="primary outline">DETAILS</button>
                <button className="primary">DOWNLOAD</button>
              </div>
            </li>
            <li>
              <div className="image-wrapper">
                <img src="/assets/images/report.png" alt="report" />
              </div>
              <span className="color-primary medium f-20">General report</span>
              <span className="color-primary bold f-20">2022</span>
              <div>
                <button className="primary outline">DETAILS</button>
                <button className="primary">DOWNLOAD</button>
              </div>
            </li>
            <li>
              <div className="image-wrapper">
                <img src="/assets/images/report.png" alt="report" />
              </div>
              <span className="color-primary medium f-20">General report</span>
              <span className="color-primary bold f-20">2018</span>
              <div>
                <button className="primary outline">DETAILS</button>
                <button className="primary">DOWNLOAD</button>
              </div>
            </li> */}
            {reports.map((report) => (
            <li>
              <div className="image-wrapper">
                <img src="/assets/images/report.png" alt="report" />
              </div>
              <span className="color-primary medium f-20">{t(`${report.fileName}`)}</span>
              <span className="color-primary bold f-20">{t(`${report.date}`)}</span>
              <div>
                {/* <button className="primary outline">DETAILS</button> */}
                <button className="primary" onClick={() => downloadReport(report.filePath)}>{t("DOWNLOAD")}</button>
              </div>
            </li>
             ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Reports;
