import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaChevronDown } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
// import Cookies from 'js-cookie';
import "./navbar.css";

const Navbar = ({ language, setLanguage }) => {
  const [openedDropdown, setOpenedDropdown] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (localStorage.getItem("language")) {
      languageHandler(localStorage.getItem("language"));
    } else {
      languageHandler("ARABIC");
    }
  }, []);
  const path = useLocation().pathname;
  const sidebarRef = useRef(null);
  const hamburgerRef = useRef(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  useEffect(() => {
    const navbarElement = document.getElementById("navbar");
    const linkTags = navbarElement.getElementsByClassName("nav-links");
    document.addEventListener("click", (event) => {
      if (
        sidebarRef?.current?.contains(event.target) ||
        hamburgerRef?.current?.contains(event.target)
      ) {
        let isNavlinkClick = false;
        for (let i = 0; i < linkTags.length; i++) {
          if (linkTags[i].contains(event.target)) {
            isNavlinkClick = true;
            break;
          }
        }
        if (isNavlinkClick) {
          setOpenSidebar(false);
          setOpenedDropdown([]);
        } else {
          setOpenSidebar(true);
        }
      } else {
        setOpenSidebar(false);
      }
    });
    return document.removeEventListener("click", () => {});
  }, [sidebarRef, hamburgerRef]);

  const languageHandler = useCallback(
    (language) => {
      localStorage.setItem("language", language);
      setLanguage(language);
      // i18next.changeLanguage(language);
    },
    [language]
  );

  useEffect(() => {
    const navbarElement = document.getElementById("navbar");
    const heroElement = document.getElementById("hero");
    document.addEventListener("scroll", (event) => {
      if (path === "/" && heroElement) {
        const { height } = heroElement.getBoundingClientRect();
        if (window.pageYOffset > height - 70) {
          navbarElement.classList = "navbar";
        } else {
          navbarElement.classList = "navbar active";
        }
      } else {
        navbarElement.classList = "navbar";
      }
    });
    return () => document.removeEventListener("click", () => {});
  }, [path]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setOpenSidebar(false);
  }, [path]);
  const openedDropdownhandler = useCallback(
    (value) => {
      const temp = [...openedDropdown];
      const index = temp.indexOf(value);
      if (index === -1) {
        temp.push(value);
      } else {
        temp.splice(index, 1);
      }
      setOpenedDropdown([...temp]);
    },
    [openedDropdown]
  );
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div id="navbar" className={path === "/" ? "navbar active" : "navbar"}>
      <div className="container-wrapper">
        <div className="logo">
          <Link to="/">
            <img
              className="image-dark"
              src="/assets/images/dark-logo.png"
              alt="logo"
              onClick={scrollToTop}
            />
          </Link>
          <img className="image" src="/assets/images/logo.png" alt="logo" />
        </div>
        <ul
          className={openSidebar ? "sidebar active" : "sidebar"}
          ref={sidebarRef}
        >
          <li>
            <Link className="nav-links" to="/">
              <span
                className={path === "/" ? "bold f-14 active" : "medium f-14"}
              >
                {t("Home")}
              </span>
            </Link>
          </li>
          <li>
            <span
              className={
                path.includes("/about-ncpd")
                  ? "bold f-14 active"
                  : "medium f-14"
              }
              onClick={() => openedDropdownhandler("about-ncpd")}
            >
              {t("aboutNCPD")}
              <FaChevronDown size={12} />
            </span>
            <div
              className={
                openedDropdown.indexOf("about-ncpd") !== -1
                  ? "dropdown active"
                  : "dropdown"
              }
            >
              <Link className="nav-links" to="/about-ncpd/about-us">
                <span
                  className={
                    path === "/about-ncpd/about-us"
                      ? "f-14 medium selected"
                      : "f-14 medium"
                  }
                >
                  {t("aboutUs")}
                </span>
              </Link>
              <Link className="nav-links" to="/about-ncpd/our-team">
                <span
                  className={
                    path === "/about-ncpd/our-team"
                      ? "f-14 medium selected"
                      : "f-14 medium"
                  }
                >
                  {t("Board_of_Directors")}
                </span>
              </Link>
            </div>
          </li>
          <li>
            <Link className="nav-links" to="/services">
              <span
                className={
                  path === "/services" ? "bold f-14 active" : "medium f-14"
                }
              >
                {t("services")}
              </span>
            </Link>
          </li>
          <li>
            <Link
              className="nav-links"
              to="/platforms/e-farmer"
            >
              <span
                className={
                  path.includes("/platforms")
                    ? "bold f-14 active"
                    : "medium f-14"
                }
              >
                {t("Platform1")}
              </span>
            </Link>
          </li>
          <li>
            <Link className="nav-links" to="/partners">
              <span
                className={
                  path === "/partners" ? "bold f-14 active" : "medium f-14"
                }
              >
                {t("Partners")}
              </span>
            </Link>
          </li>
          <li>
            <span
              className={
                path.includes("/media-center")
                  ? "bold f-14 active"
                  : "medium f-14"
              }
              onClick={() => openedDropdownhandler("media")}
            >
              {t("mediaCenter")} <FaChevronDown size={12} />
            </span>
            <div
              className={
                openedDropdown.indexOf("media") !== -1
                  ? "dropdown active"
                  : "dropdown"
              }
            >
              <Link className="nav-links" to="/media-center/news">
                <span
                  className={
                    path === "/media-center/news"
                      ? "f-14 medium selected"
                      : "f-14 medium"
                  }
                >
                  {t("News")}
                </span>
              </Link>
              {/* <Link className="nav-links" to="/media-center/events">
                <span
                  className={
                    path === "/media-center/events"
                      ? "f-14 medium selected"
                      : "f-14 medium"
                  }
                >
                  {t("Events")}
                </span>
              </Link> */}
              <Link className="nav-links" to="/media-center/reports">
                <span
                  className={
                    path === "/media-center/reports"
                      ? "f-14 medium selected"
                      : "f-14 medium"
                  }
                >
                  {t("reports")}
                </span>
              </Link>
              <Link className="nav-links" to="/media-center/statistics">
                <span
                  className={
                    path === "/media-center/statistics"
                      ? "f-14 medium selected"
                      : "f-14 medium"
                  }
                >
                  {t("statistic")}
                </span>
              </Link>
              <Link className="nav-links" to="/media-center/faqs">
                <span
                  className={
                    path === "/media-center/faqs"
                      ? "f-14 medium selected"
                      : "f-14 medium"
                  }
                >
                  {t("FAQ")}
                </span>
              </Link>
              {/* <Link className="nav-links" to="/media-center/faqs">
                <span
                  className={
                    path === "/media-center/faqs"
                      ? "f-14 medium selected"
                      : "f-14 medium"
                  }
                >
                  Domestic and International Exhibitions
                </span>
              </Link> */}
            </div>
          </li>
          <li>
            <Link className="nav-links" to="/e-library">
              <span
                className={
                  path === "/e-library" ? "bold f-14 active" : "medium f-14"
                }
              >
                {t("eLibrary")}
              </span>
            </Link>
          </li>
          <li>
            <Link className="nav-links" to="/join-us">
              <span
                className={
                  path === "/join-us" ? "bold f-14 active" : "medium f-14"
                }
              >
                {t("Join Us")}
              </span>
            </Link>
          </li>
          <li>
            <span
              onClick={() => openedDropdownhandler("language")}
              className="f-14 bold"
              style={{ font: "23px !important" }}
            >
              {/* {t(`${result}`)} */}
              {/* {t(language)} */}
              {t(language === "EN" ? "عربي" : "EN")}
            </span>
            <div
              className={
                openedDropdown.indexOf("language") !== -1
                  ? "dropdown active"
                  : "dropdown"
              }
            >
              <span
                className={
                  language === "EN" ? "f-14 medium selected" : "f-14 medium"
                }
                onClick={() => languageHandler("EN")}
              >
                EN
              </span>
              <span
                className={
                  language === "ARABIC" ? "f-14 medium selected" : "f-14 medium"
                }
                onClick={() => languageHandler("ARABIC")}
              >
                {t("ARABIC1")}
                {/* {t(language === 'ARABIC' ? 'عربي' : 'EN')} */}
              </span>
            </div>
          </li>
          <li>
            <Link className="nav-links" to="/contact-us">
              <button>
                <span className="bold f-14">{t("CONTACT_US")}</span>
              </button>
            </Link>
          </li>
        </ul>
        <div className="hamburger">
          <button ref={hamburgerRef}>
            <GiHamburgerMenu size={16} />
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLanguage: (payload) => ({
        type: "SET_LANGUAGE",
        payload,
      }),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
