export const reports = [
  {
    filePath:"GR2022.pdf",
    fileName:"General report",
    date:"2022"
  },
  {
    filePath:"GR2021.pdf",
    fileName:"General report",
    date:"2021"
  },
  {
    filePath:"GR2020.pdf",
    fileName:"General report",
    date:"2020"
  },
  {
    filePath:"GR2019.pdf",
    fileName:"General report",
    date:"2019"
  },
  {
    filePath:"GR2018.pdf",
    fileName:"General report",
    date:"2018"
  }
]

export const KingdomExport_statistics = [
  {
    title:"Year",
    fileName:"",
    date:"2016",
    filePath:"year2016.xlsx",
  },
  {
    title:"Year",
    fileName:"General report",
    date:"2017",
    filePath:"year2017.xlsx",
  },
  {
    title:"Year",
    fileName:"General report",
    date:"2018",
    filePath:"year2018.xlsx",
  },
  {
    title:"Year",
    fileName:"General report",
    date:"2019",
    filePath:"year2019.xlsx",
  },
  {
    title:"Year",
    fileName:"General report",
    date:"2020",
    filePath:"year2020.xlsx",
  },
  {
    title:"Year",
    fileName:"General report",
    date:"2021",
    filePath:"year2021.xlsx",
  }
]

export const nakheelAndKingdomFarms_statistics = [
  {
    title:"Year",
    fileName:"",
    date:"2016",
    filePath:"2016.xls",
  },
  {
    title:"Year",
    fileName:"",
    date:"2017",
    filePath:"2017.xlsx",
  },
  {
    title:"Year",
    fileName:"",
    date:"2018",
    filePath:"2018.xlsx",
  },
  {
    title:"Agriculture holding",
    fileName:"",
    date:"2018",
    filePath:"agricultureHolding.xlsx",
  }
];

export const datesProductionGlobally_statistics = [
  {
    title:"Data Production globally",
    fileName:"",
    date:"",
    filePath:"dataProductionGlobally.xlsx",
  },
  {
    title:"Data Export",
    fileName:"",
    date:"",
    filePath:"datesExport.xlsx",
  }
]