import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import Root from "./Components/Root/Root";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./reset.css";
import "./global.css";


const App = () => {
  return (
    <Provider store={store}>
        <Root />
        <ToastContainer />
    </Provider>
  );
};

export default App;
