import React, { useRef, useState, useEffect } from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";
import "./services.css";
import { data } from "../../content/service";
import { useTranslation } from "react-i18next";

const Service = () => {
  const navigate = useNavigate();
  const serviceCardRef = useRef(null);
  const introductionRef = useRef(null);
  const conditionsRef = useRef(null);
  const benificiaryRef = useRef(null);
  const relatedServicesRef = useRef(null);
  const [state, setState] = useState(1);
  const service = useParams().id;
  const serviceURL = useParams();
  const { id } = useParams();
  const location = useLocation(); // Use useLocation
  const [servicesData, setServicesData] = useState({});
  const { t } = useTranslation();

  // code
  const sectionRefs = [
    serviceCardRef,
    introductionRef,
    conditionsRef,
    benificiaryRef,
    relatedServicesRef,
  ];
  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight / 2;
    sectionRefs.forEach((ref, index) => {
      if (ref.current) {
        const sectionTop = ref.current.offsetTop;
        const sectionBottom = sectionTop + ref.current.offsetHeight;

        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          setState(index + 1);
        }
      }
    });
  };

  useEffect(() => {
    fetchData();
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [id, location.pathname, data]);

  const currentService = data.filter((s) => s.name === serviceURL.id);

  const fetchData = async () => {
    const serviceData = data.filter((item) => item.id === id) || {};
    setServicesData(serviceData);
  };

  useEffect(() => {
    fetchData();
  }, [id, data, location.pathname]);

  useEffect(() => {
    const headerElement = document.getElementById("header");
    const tabsElement = document.getElementById("tabs");
    document.addEventListener("scroll", () => {
      const { bottom } = headerElement.getBoundingClientRect();
      if (bottom < 0) {
        tabsElement.classList = "tabs active";
      } else {
        tabsElement.classList = "tabs";
      }
    });

    return () => document.removeEventListener("scroll", () => {});
  }, []);

  const isValidURL = (str) => {
    try {
      new URL(str);
      return true;
    } catch (error) {
      return false;
    }
  };
  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/">
            <h3>{t("Home")}</h3>
          </Link>
          <FaAnglesRight size={14} />
          <Link to="/services">
            <h3>{t("services")}</h3>
          </Link>
          <FaAnglesRight size={14} />
          <span>{t(`${service}`)}</span>
        </div>
      </div>
      <div className="service">
        {currentService.map((item) => (
          <div className="container-wrapper content gap-80">
            <section className="tabs-section">
              <h1 id="header" className="bold f-40 color-primary">
                {t(`${service}`)}
              </h1>
              <div className="tabs" id="tabs">
                <button
                  className={state === 1 ? "primary" : "primary outline"}
                  onClick={() => {
                    setState(1);
                    serviceCardRef?.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "center",
                    });
                  }}
                >
                  {t("SERVICE CARD")}
                </button>
                {item.introduction && (
                  <button
                    className={state === 2 ? "primary" : "primary outline"}
                    onClick={() => {
                      setState(2);
                      introductionRef?.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center",
                      });
                    }}
                  >
                    {t("INTRODUCTION")}
                  </button>
                )}
                {item.conditions && (
                  <button
                    className={state === 3 ? "primary" : "primary outline"}
                    onClick={() => {
                      setState(3);
                      conditionsRef?.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center",
                      });
                    }}
                  >
                    {t("CONDITIONS")}
                  </button>
                )}
                {item.beneficiary && (
                  <button
                    className={state === 4 ? "primary" : "primary outline"}
                    onClick={() => {
                      setState(4);
                      benificiaryRef?.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center",
                      });
                    }}
                  >
                    {t("TARGET BENEFICIARY")}
                  </button>
                )}
                {item.relatedServices && (
                  <button
                    className={state === 5 ? "primary" : "primary outline"}
                    onClick={() => {
                      setState(5);
                      relatedServicesRef?.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center",
                      });
                    }}
                  >
                    {t("RELATED SERVICES")}
                  </button>
                )}
              </div>
            </section>
            <section className="service-cards padding" ref={serviceCardRef}>
              <h1 className="bold f-28 color-primary">{t("SERVICE CARD")}</h1>
              <ul className="cards">
                {item.cardData.map((e, index) => (
                  <li key={index}>
                    <span className="medium">{index + 1}</span>
                    <div className="info">
                      <h3 className="bold f-20 color-primary">
                        {t(e.label)} {/* Removed unnecessary template string */}
                      </h3>
                      {isValidURL(e.value) ? (
                        <a
                          href={e.value}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="medium f-14 color-gray"
                        >
                          {e.isPdf ? (
                            <img src="https://ncpd.gov.sa/elnakhel/public/assets/images/filepdf.png" />
                          ) : (
                            t(e.value)
                          )}
                        </a>
                      ) : (
                        <p className="medium f-14">{t(e.value)}</p>
                      )}
                      {e.links && (
                        <p className="medium f-14 color-gray">
                          {e.links.map((item) => (
                            <>
                              {t(item.title)}(
                              <Link
                                className="medium f-14 color-gray"
                                to={item.link}
                              >
                                {t(item.link)}
                              </Link>
                              )
                              <br />
                            </>
                          ))}
                        </p>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </section>
            <section className="introduction padding" ref={introductionRef}>
              <h1 className="bold f-28 color-primary">
                {t(`${item.introduction.title}`)}
              </h1>
              <div className="info">
                <div>
                  {item?.introduction?.heading1 && (
                    <h3 className="bold f-20 color-primary">
                      {t(`${item.introduction.heading1}`)}
                    </h3>
                  )}
                  <p className="medium f-18">
                    {t(`${item.introduction.content}`)}
                  </p>
                </div>
                <div className="image-wrapper">
                  {item?.introduction?.image ? (
                    <a
                      className="full"
                      href={item.introduction.image}
                      target="_blank"
                    >
                      <img src={item.introduction.image} alt="introduction" />
                      <p className="bold f-20 color-primary">
                        {t("Click on the image to see the steps")}
                      </p>
                    </a>
                  ) : (
                    <img
                      src="/assets/images/aboutus-2.png"
                      alt="introduction"
                    />
                  )}
                </div>
              </div>
            </section>
            {item.steps && (
              <section className="introduction padding">
                <h1 className="bold f-28 color-primary">{t(`Steps`)}</h1>
                <ul>
                  {item.steps.map((el, idx) => (
                    <li>
                      <h4 className="f-16 color-primary medium">
                        {idx + 1}. {t(el.title)}
                      </h4>
                      <p className="f-14 color-gray medium">
                        -{t(el.content)}{" "}
                        {el.url && (
                          <>
                            (
                            <Link
                              className="f-14 color-gray medium"
                              to={el.url}
                            >
                              {el.url}
                            </Link>
                            )
                          </>
                        )}
                      </p>
                    </li>
                  ))}
                </ul>
              </section>
            )}
            {item.conditions && (
              <section className="padding requirements" ref={conditionsRef}>
                <h1 className="bold f-28 color-primary">
                  {t(`${item.conditions.title}`)}
                </h1>
                <ul>
                  {item.conditions.cond1 && (
                    <li>
                      <img src="/assets/images/check.png" alt="check" />
                      <p className="medium f-20 color-primary">
                        {t(`${item.conditions.cond1}`)}
                      </p>
                    </li>
                  )}
                  {item.conditions.cond2 && (
                    <li>
                      <img src="/assets/images/check.png" alt="check" />
                      <p className="medium f-20 color-primary">
                        {t(`${item.conditions.cond2}`)}
                      </p>
                    </li>
                  )}
                  {item.conditions.cond3 && (
                    <li>
                      <img src="/assets/images/check.png" alt="check" />
                      <p className="medium f-20 color-primary">
                        {t(`${item.conditions.cond3}`)}
                      </p>
                    </li>
                  )}
                  {item.conditions.cond4 && (
                    <li>
                      <img src="/assets/images/check.png" alt="check" />
                      <p className="medium f-20 color-primary">
                        {t(`${item.conditions.cond4}`)}
                      </p>
                    </li>
                  )}
                  {item.conditions.cond5 && (
                    <li>
                      <img src="/assets/images/check.png" alt="check" />
                      <p className="medium f-20 color-primary">
                        {t(`${item.conditions.cond5}`)}
                      </p>
                    </li>
                  )}
                  {item.conditions.cond6 && (
                    <li>
                      <img src="/assets/images/check.png" alt="check" />
                      <p className="medium f-20 color-primary">
                        {t(`${item.conditions.cond6}`)}
                      </p>
                    </li>
                  )}
                  {item.conditions.cond7 && (
                    <li>
                      <img src="/assets/images/check.png" alt="check" />
                      <p className="medium f-20 color-primary">
                        {t(`${item.conditions.cond7}`)}
                      </p>
                    </li>
                  )}
                </ul>
              </section>
            )}
            {item.conditionInSteps?.data?.map((el, index) => (
              <section className="get-service padding">
                <div className="box-wrapper  get-service padding">
                  <div key={index}>
                    <h3
                      className="bold f-28"
                      style={{ color: "white", textAlign: "justify" }}
                    >
                      {t(el.title)}
                    </h3>
                    <ul>
                      {el.list?.map((el1) => (
                        <li>
                          <p className="medium f-16">{t(el1)}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </section>
            ))}

            {item.beneficiary && (
              <section className="introduction padding" ref={benificiaryRef}>
                <h1 className="bold f-28 color-primary">
                  {t(`${item.beneficiary.title}`)}
                </h1>
                <div className="info">
                  <div>
                    <h3 className="bold f-20 color-primary">
                      {t(`${item.beneficiary.heading}`)}
                    </h3>
                    <p className="medium f-18">
                      {t(`${item.beneficiary.content}`)}
                    </p>
                  </div>
                  <div className="image-wrapper">
                    <img src={item.beneficiary.image} alt="" />
                  </div>
                </div>
              </section>
            )}
            {item.objectives && (
              <section className="introduction padding">
                <h1 className="bold f-28 color-primary">{t(`Objectives`)}</h1>
                <ul>
                  {item.objectives.map((el, idx) => (
                    <li>
                      <h4 className="f-16 color-primary medium">
                        {idx + 1}. {t(el)}
                      </h4>
                    </li>
                  ))}
                </ul>
              </section>
            )}
            {item.sections && (
              <section className="introduction padding">
                <h1 className="bold f-28 color-primary">{t(`Sections`)}</h1>
                <ul>
                  {item.sections.map((el, idx) => (
                    <li>
                      <h4 className="f-16 color-primary medium">
                        {idx + 1}. {t(el.content)}
                      </h4>
                      {el.subs?.map((sub) => (
                        <p className="f-16 color-gray medium">-{t(sub)}</p>
                      ))}
                    </li>
                  ))}
                </ul>
              </section>
            )}
            {item?.getService && (
              <section className="get-service padding">
                <div className="box-wrapper">
                  {item.getService?.data?.map((el, index) => (
                    <div key={index}>
                      <h3
                        className="bold f-28"
                        style={{ color: "white", textAlign: "justify" }}
                      >
                        {t(el.title)}
                      </h3>
                      <ul>
                        {el.list?.map((el1) => (
                          <li>
                            <p className="medium f-18">{t(el1)}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                  <div>
                    {item?.getService?.content && (
                      <h1 className="bold f-28">
                        {t(item?.getService?.content)}
                      </h1>
                    )}
                    {item.getService?.isClickTextRequired &&
                      item.getService?.link && (
                        <p className="medium f-18">
                          {t("Click the button to access the service")}
                        </p>
                      )}
                  </div>
                  {item?.getService?.link && (
                    <Link to={item?.getService?.link}>
                      <button className="primary white">
                        {t("GET THE SERVICE")}
                      </button>
                    </Link>
                  )}
                </div>
              </section>
            )}
            {item.relatedServices && (
              <section
                className="service-cards padding"
                ref={relatedServicesRef}
              >
                <h1 className="bold f-28 color-primary">
                  {t("RELATED SERVICES")}
                </h1>
                <ul className="cards">
                  {item?.relatedServices?.map((e, index) => (
                    <Link to={`${e.url}`} className="medium f-16 color-gray">
                      <li key={index}>{t(e.title)}</li>
                    </Link>
                  ))}
                </ul>
              </section>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Service;
