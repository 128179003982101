import React, { useState, useCallback, useEffect } from "react";
import {
  FaAnglesRight,
  FaChevronDown,
  FaChevronUp,
  FaCheck,
  FaArrowRight,
} from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import "./library.css";
import { useTranslation } from "react-i18next";
const launchDate = new Date('2024-03-28T00:00:00');

const ELibrary = () => {
  const [open, setOpen] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const {t} = useTranslation();
  const selectedFiltersHandler = useCallback(
    (filter) => {
      const temp = [...selectedFilters];
      const index = temp.indexOf(filter);
      if (index === -1) {
        temp.push(filter);
      } else {
        temp.splice(index, 1);
      }
      setSelectedFilters([...temp]);
    },
    [selectedFilters]
  );

  // temporary start
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  function calculateTimeRemaining() {
    const now = new Date();
    return launchDate.getTime() - now.getTime();
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []); 
  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
  return (
    //temporary
    <div className="coming-soon-container">
      <div className="coming-soon-content">
        <h1>Coming Soon...</h1>
        <p>We're working hard to bring you something amazing!</p>
        <div>
          <p>Time remaining until launch:</p>
          <p>{days} days, {hours} hours, {minutes} minutes, {seconds} seconds</p>
        </div>
      </div>
    </div>
  // temporary end

    // <div className="container">
    //   <div className="breadcrumbs">
    //     <div className="container-wrapper">
    //       <Link to="/"><h3>{t("Home")}</h3></Link>
    //       <FaAnglesRight size={14} />
    //       <span>E-Library</span>
    //     </div>
    //   </div>
    //   <div className="content library">
    //     <div className="container-wrapper">
    //       <h1 className="bold f-40 color-primary">E-Library</h1>
    //       <section>
    //         <div className="filters-section">
    //           <div className="headers">
    //             <span className="f-20 medium color-primary">Filter</span>
    //             <button
    //               onClick={() => setSelectedFilters([])}
    //               className="bold f-16"
    //             >
    //               <IoMdClose size={20} />
    //             </button>
    //           </div>
    //           <div className="filters">
    //             <h3 className={open ? "medium f-20 active" : "medium f-20"}>
    //               Our Services
    //               {open ? (
    //                 <button onClick={() => setOpen(false)}>
    //                   <FaChevronUp size={14} />
    //                 </button>
    //               ) : (
    //                 <button onClick={() => setOpen(true)}>
    //                   <FaChevronDown size={14} />
    //                 </button>
    //               )}
    //             </h3>
    //             <ul className={open ? "active" : ""}>
    //               <li>
    //                 <button
    //                   onClick={() =>
    //                     selectedFiltersHandler("awareness-and-guidance")
    //                   }
    //                 >
    //                   {selectedFilters.indexOf("awareness-and-guidance") ===
    //                   -1 ? (
    //                     ""
    //                   ) : (
    //                     <FaCheck size={14} />
    //                   )}
    //                 </button>
    //                 <span
    //                   className={
    //                     selectedFilters.indexOf("awareness-and-guidance") !== -1
    //                       ? "bold f-14"
    //                       : "medium f-14"
    //                   }
    //                 >
    //                   Awareness and guidance
    //                 </span>
    //               </li>
    //               <li>
    //                 <button onClick={() => selectedFiltersHandler("statics")}>
    //                   {selectedFilters.indexOf("statics") === -1 ? (
    //                     ""
    //                   ) : (
    //                     <FaCheck size={14} />
    //                   )}
    //                 </button>
    //                 <span
    //                   className={
    //                     selectedFilters.indexOf("statics") !== -1
    //                       ? "bold f-14"
    //                       : "medium f-14"
    //                   }
    //                 >
    //                   Statics
    //                 </span>
    //               </li>
    //               <li>
    //                 <button onClick={() => selectedFiltersHandler("research")}>
    //                   {selectedFilters.indexOf("research") === -1 ? (
    //                     ""
    //                   ) : (
    //                     <FaCheck size={14} />
    //                   )}
    //                 </button>
    //                 <span
    //                   className={
    //                     selectedFilters.indexOf("research") !== -1
    //                       ? "bold f-14"
    //                       : "medium f-14"
    //                   }
    //                 >
    //                   Research
    //                 </span>
    //               </li>
    //               <li>
    //                 <button
    //                   onClick={() => selectedFiltersHandler("trade-and-market")}
    //                 >
    //                   {selectedFilters.indexOf("trade-and-market") === -1 ? (
    //                     ""
    //                   ) : (
    //                     <FaCheck size={14} />
    //                   )}
    //                 </button>
    //                 <span
    //                   className={
    //                     selectedFilters.indexOf("trade-and-market") !== -1
    //                       ? "bold f-14"
    //                       : "medium f-14"
    //                   }
    //                 >
    //                   Trade and market
    //                 </span>
    //               </li>
    //               <li>
    //                 <button onClick={() => selectedFiltersHandler("technical")}>
    //                   {selectedFilters.indexOf("technical") === -1 ? (
    //                     ""
    //                   ) : (
    //                     <FaCheck size={14} />
    //                   )}
    //                 </button>
    //                 <span
    //                   className={
    //                     selectedFilters.indexOf("technical") !== -1
    //                       ? "bold f-14"
    //                       : "medium f-14"
    //                   }
    //                 >
    //                   Technical
    //                 </span>
    //               </li>
    //               <li>
    //                 <button
    //                   onClick={() =>
    //                     selectedFiltersHandler(
    //                       "sustainability-and-scientific-research"
    //                     )
    //                   }
    //                 >
    //                   {selectedFilters.indexOf(
    //                     "sustainability-and-scientific-research"
    //                   ) === -1 ? (
    //                     ""
    //                   ) : (
    //                     <FaCheck size={14} />
    //                   )}
    //                 </button>
    //                 <span
    //                   className={
    //                     selectedFilters.indexOf(
    //                       "sustainability-and-scientific-research"
    //                     ) !== -1
    //                       ? "bold f-14"
    //                       : "medium f-14"
    //                   }
    //                 >
    //                   Sustainability and Scientific Research
    //                 </span>
    //               </li>
    //               <li>
    //                 <button onClick={() => selectedFiltersHandler("report")}>
    //                   {selectedFilters.indexOf("report") === -1 ? (
    //                     ""
    //                   ) : (
    //                     <FaCheck size={14} />
    //                   )}
    //                 </button>
    //                 <span
    //                   className={
    //                     selectedFilters.indexOf("report") !== -1
    //                       ? "bold f-14"
    //                       : "medium f-14"
    //                   }
    //                 >
    //                   Report
    //                 </span>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //         <div className="books">
    //           <div className="input-wrapper">
    //             <input placeholder="Search" />
    //             <FiSearch size={20} />
    //           </div>
    //           <ul>
    //             <li>
    //               <div className="image-wrapper">
    //                 <img src="/assets/images/book.png" alt="book" />
    //               </div>
    //               <div className="info">
    //                 <h3 className="color-primary bold f-20">Lorem Ispum</h3>
    //                 <p className="color-gray medium f-14">
    //                   Lorem ipsum dolor sit amet consectetur.
    //                 </p>
    //                 <button className="bold f-16">
    //                   Read more
    //                   <FaArrowRight size={16} />
    //                 </button>
    //               </div>
    //             </li>
    //             <li>
    //               <div className="image-wrapper">
    //                 <img src="/assets/images/book.png" alt="book" />
    //               </div>
    //               <div className="info">
    //                 <h3 className="color-primary bold f-20">Lorem Ispum</h3>
    //                 <p className="color-gray medium f-14">
    //                   Lorem ipsum dolor sit amet consectetur.
    //                 </p>
    //                 <button className="bold f-16">
    //                   Read more
    //                   <FaArrowRight size={16} />
    //                 </button>
    //               </div>
    //             </li>
    //             <li>
    //               <div className="image-wrapper">
    //                 <img src="/assets/images/book.png" alt="book" />
    //               </div>
    //               <div className="info">
    //                 <h3 className="color-primary bold f-20">Lorem Ispum</h3>
    //                 <p className="color-gray medium f-14">
    //                   Lorem ipsum dolor sit amet consectetur.
    //                 </p>
    //                 <button className="bold f-16">
    //                   Read more
    //                   <FaArrowRight size={16} />
    //                 </button>
    //               </div>
    //             </li>
    //             <li>
    //               <div className="image-wrapper">
    //                 <img src="/assets/images/book.png" alt="book" />
    //               </div>
    //               <div className="info">
    //                 <h3 className="color-primary bold f-20">Lorem Ispum</h3>
    //                 <p className="color-gray medium f-14">
    //                   Lorem ipsum dolor sit amet consectetur.
    //                 </p>
    //                 <button className="bold f-16">
    //                   Read more
    //                   <FaArrowRight size={16} />
    //                 </button>
    //               </div>
    //             </li>
    //             <li>
    //               <div className="image-wrapper">
    //                 <img src="/assets/images/book.png" alt="book" />
    //               </div>
    //               <div className="info">
    //                 <h3 className="color-primary bold f-20">Lorem Ispum</h3>
    //                 <p className="color-gray medium f-14">
    //                   Lorem ipsum dolor sit amet consectetur.
    //                 </p>
    //                 <button className="bold f-16">
    //                   Read more
    //                   <FaArrowRight size={16} />
    //                 </button>
    //               </div>
    //             </li>
    //             <li>
    //               <div className="image-wrapper">
    //                 <img src="/assets/images/book.png" alt="book" />
    //               </div>
    //               <div className="info">
    //                 <h3 className="color-primary bold f-20">Lorem Ispum</h3>
    //                 <p className="color-gray medium f-14">
    //                   Lorem ipsum dolor sit amet consectetur.
    //                 </p>
    //                 <button className="bold f-16">
    //                   Read more
    //                   <FaArrowRight size={16} />
    //                 </button>
    //               </div>
    //             </li>
    //             <li>
    //               <div className="image-wrapper">
    //                 <img src="/assets/images/book.png" alt="book" />
    //               </div>
    //               <div className="info">
    //                 <h3 className="color-primary bold f-20">Lorem Ispum</h3>
    //                 <p className="color-gray medium f-14">
    //                   Lorem ipsum dolor sit amet consectetur.
    //                 </p>
    //                 <button className="bold f-16">
    //                   Read more
    //                   <FaArrowRight size={16} />
    //                 </button>
    //               </div>
    //             </li>
    //             <li>
    //               <div className="image-wrapper">
    //                 <img src="/assets/images/book.png" alt="book" />
    //               </div>
    //               <div className="info">
    //                 <h3 className="color-primary bold f-20">Lorem Ispum</h3>
    //                 <p className="color-gray medium f-14">
    //                   Lorem ipsum dolor sit amet consectetur.
    //                 </p>
    //                 <button className="bold f-16">
    //                   Read more
    //                   <FaArrowRight size={16} />
    //                 </button>
    //               </div>
    //             </li>
    //             <li>
    //               <div className="image-wrapper">
    //                 <img src="/assets/images/book.png" alt="book" />
    //               </div>
    //               <div className="info">
    //                 <h3 className="color-primary bold f-20">Lorem Ispum</h3>
    //                 <p className="color-gray medium f-14">
    //                   Lorem ipsum dolor sit amet consectetur.
    //                 </p>
    //                 <button className="bold f-16">
    //                   Read more
    //                   <FaArrowRight size={16} />
    //                 </button>
    //               </div>
    //             </li>
    //           </ul>
    //         </div>
    //       </section>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ELibrary;
