import React, { useEffect } from "react";

const Initiative = () => {
  useEffect(() => {
    window.location.href =
      "https://old.ncpd.gov.sa/services/page/Factory/initiative";
  }, []);
  return <></>;
};

export default Initiative;
