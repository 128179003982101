import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./partners.css";
// import { Link } from "react-router-dom";
import ServiceProvider from "./ServiceProvider";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { approvedCenter } from "../../content/service";

function ApprovedCenters() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tempValue, setTempValue] = useState(null);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  const calculateComponentsToSlide = () => {
    return window.screen.width < 576 ? 1 : 2;
  };
  const updatedScreenSize = () => {
    setCurrentWidth(window.innerWidth);
  };
  function toggleAnimation(slide) {
    const isArabic = slide.classList.contains("arabic");
    slide.classList.toggle("slide-in", !isArabic);
    slide.classList.toggle("arabic", isArabic);
  }

  window.addEventListener("resize", updatedScreenSize);
  useEffect(() => {
    calculateComponentsToSlide();
  }, [currentWidth]);

  const prevSlide = useCallback(() => {
    console.log(currentIndex);
    if (currentIndex >= calculateComponentsToSlide()) {
      setCurrentIndex((prevIndex) => prevIndex - calculateComponentsToSlide());
    } else {
      setCurrentIndex(0);
    }
  }, [currentIndex]);

  const nextSlide = useCallback(() => {
    const maxIndex = approvedCenter.length - calculateComponentsToSlide();

    if (currentIndex < maxIndex) {
      setCurrentIndex((prevIndex) => prevIndex + calculateComponentsToSlide());
      console.log("curr=>", currentIndex);
    } else {
      setCurrentIndex(maxIndex);
    }
    // toggleAnimation(slides[currentIndex]);
  }, [currentIndex]);

  useEffect(() => {}, [currentIndex]);

  const handleSelect = (e) => {
    e.preventDefault();
    const cityName = e.target.value;
    setTempValue(cityName);
    if (window.innerWidth > 576) {
      switch (cityName) {
        case "Al-Qassim":
          setCurrentIndex(0);
          break;
        case "Riyadh":
          setCurrentIndex(2);
          break;
        case "Al-Madinah":
          setCurrentIndex(1);
          break;
        case "Al-Ahsa":
          setCurrentIndex(4);
          break;
        default:
          setCurrentIndex(0);
          break;
      }
    }else{
      switch (cityName) {
        case "Al-Qassim":
          setCurrentIndex(1);
          break;
        case "Riyadh":
          setCurrentIndex(3);
          break;
        case "Al-Madinah":
          setCurrentIndex(4);
          break;
        case "Al-Ahsa":
          setCurrentIndex(5);
          break;
        default:
          setCurrentIndex(0);
          break;
      }
    }
  };

  const cities = ["All Cities", "Al-Qassim", "Riyadh", "Al-Madinah", "Al-Ahsa"];

  const { t } = useTranslation();
  return (
    <div className="approved-container">
      <div className="selection-header">
        <h3 className="color-primary f-20 bold">
          {t("The services providers")}
        </h3>
        <div className="dropdown">
          <select
            style={{ width: "15rem", border: "1px solid #9bd2a5" }}
            onChange={handleSelect}
            value={tempValue}
          >
            <option value={null} disabled hidden>
              {t("Sort by city")}
            </option>
            {cities.map((city, index) => (
              <option key={index} value={city} className="medium f-18">
                {t(city)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="slider-component">
        {approvedCenter
          .slice(currentIndex, currentIndex + calculateComponentsToSlide())
          .map((center, index) => (
            <div key={index}>
              <ServiceProvider key={index} center={center} />
            </div>
          ))}
      </div>
      <div className="slider-arrow">
        <ul>
          <li>
            <button
              className={
                currentIndex < calculateComponentsToSlide()
                  ? "primary outline"
                  : "primary"
              }
              onClick={prevSlide}
            >
              <FaArrowLeft size={20} />
            </button>
          </li>
          <li>
            <button
              className={
                currentIndex >=
                approvedCenter.length - calculateComponentsToSlide()
                  ? "primary outline"
                  : "primary"
              }
              onClick={nextSlide}
            >
              <FaArrowRight size={20} />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ApprovedCenters;
