import React from "react";
import { Routes as Switch, Route, Navigate } from "react-router-dom";
import Home from "../../Pages/Home/Home";
import AboutUs from "../../Pages/AboutUS/AboutUS";
import OurTeam from "../../Pages/AboutUS/OurTeam";
import Services from "../../Pages/Services/Services";
import Service from "../../Pages/Services/Service";
import Platforms from "../../Pages/Platforms/Platforms";
import News from "../../Pages/MediaCenter/News";
import Events from "../../Pages/MediaCenter/Events";
import Reports from "../../Pages/MediaCenter/Reports";
import Statistics from "../../Pages/MediaCenter/Statistics";
import FAQs from "../../Pages/MediaCenter/FAQs";
import ELibrary from "../../Pages/ELibrary/ELibrary";
import ContactUS from "../../Pages/ContactUS/ContactUS";
import Partners from "../../Pages/Partners/Partners";
import PartnersDetails from "../../Pages/Partners/PartnerDetails";
import JoinUs from "../../Pages/JoinUs/JoinUs";
import NewsDetail from "../../Pages/MediaCenter/NewsDetail";
import Initiative from "../../Pages/Initiative/Initiative";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" element={<Home />} />
      <Route exact path="*" element={<Navigate to="/" />} />
      <Route exact path="/about-ncpd/about-us" element={<AboutUs />} />
      <Route exact path="/about-ncpd/our-team" element={<OurTeam />} />
      <Route exact path="/services" element={<Services />} />
      <Route exact path="/services/:id" element={<Service />} />
      <Route exact path="/services/page/Factory/initiative" element={<Initiative />} />
      <Route exact path="/platforms/:id" element={<Platforms />} />
      <Route exact path="/media-center/news" element={<News />} />
      <Route exact path="/media-center/news/:id" element={<NewsDetail />} />
      {/* <Route exact path="/media-center/events" element={<Events />} /> */}
      <Route exact path="/media-center/reports" element={<Reports />} />
      <Route exact path="/media-center/statistics" element={<Statistics />} />
      <Route exact path="/media-center/faqs" element={<FAQs />} />
      <Route exact path="/e-library" element={<ELibrary />} />
      <Route exact path="/e-library/*" element={<Navigate to="/e-library" />} />
      <Route exact path="/contact-us" element={<ContactUS />} />
      <Route exact path="/partners" element={<Partners />} />
      <Route exact path="/partners/:partnerId" element={<PartnersDetails />} />
      <Route exact path="/join-us" element={<JoinUs />} />
    </Switch>
  );
};

export default Routes;
