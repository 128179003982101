import React, { useState } from 'react'
import { FaEye } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

function FumigationTable() {
  const [selectedCity, setSelectedCity] = useState(null)
  const [tempValue, setTempValue] = useState(null)

  const { t } = useTranslation()
  return (
    <div id='laboratories-table'>
      <div style={styles.dropdown} className='approve__dropdown'>
        <select
          style={styles.dropdownSelect}
          onChange={(e) => {
            setTempValue(e.target.value)
          }}
          value={tempValue}
        >
          <option value={null} disabled selected hidden>{t("Choose City")}</option>
          {cities.map((city, idx) => (
            <option value={city} key={idx} className="medium f-18">{t(city)}</option>
          ))}
        </select>
        <button
          className='medium f-14'
          onClick={() => {
            setSelectedCity(tempValue)
          }}
          style={styles.btn}
        >
          {t('search')}
        </button>
      </div>
      <div className='table'>
        <table style={styles.table}>
          <thead style={styles.thead}>
            <tr>
              {header.map((ob) => (
                <th className='medium f-14' key={ob.id} id={ob.id} style={styles.thtd}>
                  {t(ob.name)}
                </th>
              ))}
            </tr>
          </thead>
          {rows
            .filter((ob) => {
              // console.log(selectedCity)
              if (!selectedCity || selectedCity === 'Choose City') {
                return true
              }
              if (!selectedCity || selectedCity === 'All Cities') {
                return true
              }
              return ob.City == selectedCity
            })
            .map((ob) => (
              <tr key={ob.key}>
                <td className='medium f-14' style={styles.thtd}>
                  {ob.id}
                </td>
                <td className='medium f-14' style={styles.thtd}>
                  {t(ob.FactoryName)}
                </td>
                <td className='medium f-14' style={styles.thtd}>
                  {t(ob.City)}
                </td>
                <td
                  className='medium f-14'
                  style={{
                    ...styles.thtd,
                    color: '#9bd2a5',
                    textAlign: 'center',
                    cursor: 'pointer',
                    // display: 'flex',
                    // justifyContent: 'center',
                    // alignItems: 'center',
                    gap: '1rem',
                  }}
                  onClick={() => window.open(ob.locationUrl)}
                >
                  <div style={styles.eyeIcon}>

                  <FaEye size={16} />{' '}
                  <span className='medium f-14'>{t('see_on_maps')}</span>
                  </div>
                </td>
              </tr>
            ))}
        </table>
      </div>
    </div>
  )
}

export default FumigationTable

const rows = [
  {
    id: 1,
    FactoryName: 'Al-Daif Dates Factory and its Derivatives',
    City: 'Al-Qassim',
    locationUrl: 'https://maps.app.goo.gl/X7dtbfuzZTLc45MR6',
  },
  {
    id: 2,
    FactoryName: 'Ratab Al-Watan Dates Factory',
    City: 'Riyadh',
    locationUrl: 'https://maps.app.goo.gl/EpfFJhhmHZLjYUYu5',
  },
  {
    id: 3,
    FactoryName: 'Sultan Dates Factory',
    City: 'Al-Madinah',
    locationUrl: 'https://maps.app.goo.gl/s3mut9tXeHkEc2Xp7',
  },
  {
    id: 4,
    FactoryName: 'Al-Nakhil Farms for Packing and Packaging',
    City: 'Al-Qassim',
    locationUrl: 'https://maps.app.goo.gl/C5QxGDR3RS8sadnr6',
  },
  {
    id: 5,
    FactoryName: 'AlEmtiaz Factory for Dates Processing',
    City: 'Al-Qassim',
    locationUrl: 'https://maps.app.goo.gl/xLvqr892HwVDUCCy7',
  },
  {
    id: 6,
    FactoryName: 'Al-Rafaie Farm Dates Factory',
    City: 'Al-Ahsa',
    locationUrl: 'https://maps.app.goo.gl/b7DBGahzoVNCjfZq6',
  },
  {
    id: 7,
    FactoryName:
      'Cooperative Agricultural Society in Al-Badaea, Thmarat Factory for Packing and Packaging',
    City: 'Al-Qassim',
    locationUrl: 'https://maps.app.goo.gl/y7hceQbLBaNQHTEk7',
  },
  {
    id: 8,
    FactoryName: 'Tamrati Factory',
    City: 'Al-Madinah',
    locationUrl: 'https://maps.app.goo.gl/MsDGQbRw9FGFE1F66',
  },
  {
    id: 9,
    FactoryName: "Laha'a for Food Products Factory",
    City: 'Riyadh',
    locationUrl: 'https://maps.app.goo.gl/Tauon4UofoD4aUxp7',
  },
  {
    id: 10,
    FactoryName: 'Taybah Dates Factory',
    City: 'Al-Madinah',
    locationUrl: 'https://maps.app.goo.gl/vjRYS8Xfd17euEwX6',
  },
  {
    id: 11,
    FactoryName: 'Noah AlMedina Company for Dates and Ready-to-Eat Foods',
    City: 'Al-Madinah',
    locationUrl: 'https://maps.app.goo.gl/ccsz3TeKpxiHsB7y8',
  },
  {
    id: 12,
    FactoryName: 'Al-Mohammadia Dates Company',
    City: 'Riyadh',
    locationUrl: 'https://maps.app.goo.gl/nPAubjYvqSr49re67',
  },
  {
    id: 13,
    FactoryName: 'Siyafa International Company for Industry',
    City: 'Makkah',
    locationUrl: 'https://maps.app.goo.gl/qJAmhrgHbqxkBhLf6',
  },
  {
    id: 14,
    FactoryName: 'Dates of Onaiza Company',
    City: 'Al-Qassim',
    locationUrl: 'https://maps.app.goo.gl/D4VSPFaa53wVn5xB6',
  },
  {
    id: 15,
    FactoryName: 'Naif Al-Zahary Dates Factory',
    City: 'Riyadh',
    locationUrl: 'https://maps.app.goo.gl/TMMFPZ5AgoZK3Ew48',
  },
  {
    id: 16,
    FactoryName: 'Hamed Obeid Allah Al-Khamash Industry',
    City: 'Riyadh',
    locationUrl: 'https://maps.app.goo.gl/b2Q7CBaV52uF61oZA',
  },
  {
    id: 17,
    FactoryName: 'Basqat AlQassim Dates Factory',
    City: 'Al-Qassim',
    locationUrl: 'https://maps.app.goo.gl/W6VUQb165xX6cEkJ8',
  },
  {
    id: 18,
    FactoryName: 'Nakhlah for Food Industries',
    City: 'Eastern Province',
    locationUrl: 'https://maps.app.goo.gl/2sNSiKaAV8gVAdYq8',
  },
  {
    id: 19,
    FactoryName: 'Atiab Al-Bawadi Dates Factory',
    City: 'Riyadh',
    locationUrl: 'https://maps.app.goo.gl/kgXees8vrRaBeee78',
  },
  {
    id: 20,
    FactoryName: 'Sana Al-Madina Dates Company',
    City: 'Al-Madinah',
    locationUrl: 'https://maps.app.goo.gl/CuY9gNq8BVXfDngR7',
  },
  {
    id: 21,
    FactoryName: "Al-Qal'ah Dates Factory",
    City: 'Al-Madinah',
    locationUrl: 'https://maps.app.goo.gl/Lw4TZpHnAQ5JYMQT8',
  },
]

const header = [
  { name: 'ID', id: 'id' },
  { name: 'Factory Name', id: 'factoryName' },
  { name: 'City', id: 'city' },
  { name: 'LocationLink', id: 'locationUrl' },
]

const cities = [
  'All Cities',
  'Al-Qassim',
  'Riyadh',
  'Al-Madinah',
  'Al-Ahsa',
  'Makkah',
  'Eastern Province',
]

const styles = {
  btn: {
    backgroundColor: '#1e495c',
    color: '#fff',
    textAlign: 'center',
    height: '2.75rem',
    borderRadius: '8px',
    width: '10rem',
  },
  dropdownSelect: { width: '15rem', border: '1px solid #1e495c' },
  dropdown: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '1rem',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  thtd: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '1rem 2rem',
  },
  thead: { backgroundColor: '#1e495c', color: 'white' },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
  },
  eyeIcon:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    gap:"1rem"
  }
}
