import React from "react";
import { FaAnglesRight, FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import "./partners.css";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { partnerDetailsData } from "../../content/partners";

const PartnersDetails = () => {
  const Id = useParams().partnerId;
  const partner = partnerDetailsData.filter((item) => item.partnerId === Id);
  const redirectToFugmentation = () => {
    window.open("https://ncpd.gov.sa/services/page/export/Fumigation");
  };
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/"><h3>{t("Home")}</h3></Link>
          <FaAnglesRight size={14} />
          <Link to="/partners"><span>{t("Partner")}</span></Link>
          <FaAnglesRight size={14} />
          <span>{t(`${Id}`)}</span>
        </div>
      </div>
      {partner.map((item, idx) => (
        <div className="partner-details content" key={idx}>
          <h1 className="bold f-40 color-primary">{t(`${item.partner}`)}</h1>
          <div className="container-wrapper">
            <div className="detail_section">
              <h3 className="bold f-28 color-primary">
                {t("Media_center_Description")}
              </h3>
              <div className="details">
                <div className="details_text">
                  <h4 className="medium f-20 color-primary">
                    {t(`${item.partner}`)}
                  </h4>
                  <p className="medium f-16 color-gray">
                    {t(`${item.description}`)}
                  </p>
                </div>
                <div className="image-wrapper">
                  <img
                    src={`/assets/images/${item.image}`}
                    alt={item.partnerId}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* 2nd div */}
          {item.services?.length > 0 && (
            <div className="detail_section">
              <h3 className="bold f-28 color-primary"> {t("services")} </h3>
              <div className="details service">
                {item.services &&
                  item.services?.map((service, idx) => (
                    <div className="service_section" key={idx}>
                      <h4 className="medium f-20 color-primary">
                        {t(`${service.title}`)}
                      </h4>
                      <p className="medium f-16 color-gray">
                        {t(`${service.description}`)} <br />
                      </p>
                      <Link
                        to={service.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="medium f-14 color-primary"
                      >
                        <b />
                        {t(`Click here for more`)}
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          )}
          {item.requirements?.length > 0 && (
            <div className="requirements">
              {item.requirements.map((el) => (
                <div className="requirement">
                  <h3 className="color-primary f-20 medium"> {t(el.title)}:</h3>
                  {el.data.map((el1, idx) => (
                    <p className="color-gray f-14 medium">{t(el1)}</p>
                  ))}
                </div>
              ))}
            </div>
          )}
          {item.pdfs?.length > 0 && (
            <div className="pdfs container-wrapper">
              {item.pdfs.map((el) => (
                <div className="pdf">
                  <Link to={el.url}>
                    <img src="https://ncpd.gov.sa/elnakhel/public/assets/images/filepdf.png" />
                  </Link>
                  <h3 className="color-primary f-20 medium">{t(el.title)}</h3>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PartnersDetails;
