import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import { GrLocation } from "react-icons/gr";
import { BsTelephone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { newsContent, tweets } from "../../content/news";
import "./home.css";
import { useTranslation } from "react-i18next";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import { data } from "../../content/service";
import { events2024, updatedEvent2024 } from "../../content/events";
// import Tweet from "../../Components/Twitter/tweet";
import { Tweet } from "react-tweet";

const center = {
  lat: 24.7602772,
  lng: 46.605116,
};

const containerStyle = {
  width: "100%",
  height: "100%",
};

const Home = () => {
  const navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC1Cz13aBYAbBYJL0oABZ8KZnd7imiWwA4",
  });
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const leapYearDays = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const nonLeapYearDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const [heroImage, setHeroImage] = useState("/assets/images/home.jpg");
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedOpenTab, setSelectedOpenTab] = useState(null);
  const [selectedCalendarTab, setSelectedCalendarTab] = useState(1);
  const [expandedNews, setExpandedNews] = useState({});
  const [servicesData, setServicesData] = useState([
    {
      name: "Services",
      description:
        "Encompasses services provided directly by the center to clients, such as financial support, training, and technical consultations, aiming to enhance and improve the date palm farming and date production sector.",
      index: 0,
      data: [],
    },
    {
      name: "Knowledge",
      description:
        "Includes all knowledge and educational services, such as training courses, educational resources, and technical information, with the goal of improving date palm farming practices and promoting awareness and understanding.",
      index: 1,
      data: [],
    },
    {
      name: "Support",
      description:
        "Relates to services provided through partners or collaborative programs, including practical support for farmers and operators in the date palm farming sector.",
      index: 2,
      data: [],
    },
  ]);
  const [calendar, setCalendar] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    days: [],
  });

  const { t } = useTranslation();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const address = "Al-Dahi, Hittin, Riyadh 13512";

  const handleAddressClick = () => {
    const googleMapsLink = `https://www.google.com/maps/place/${encodeURIComponent(
      address
    )}`;
    window.open(googleMapsLink, "_blank");
  };

  const selectedCalendarTabHandler = useCallback((index) => {
    setSelectedCalendarTab(index);
  }, []);
  const selectedTabHandler = useCallback(
    (index) => {
      const heroImages = [
        "/assets/images/farmer.jpg",
        "/assets/images/factory.JPG",
        "/assets/images/investor.jpg",
      ];
      if (selectedTab === index) {
        setSelectedTab(null);
        setHeroImage("/assets/images/home.jpg");
      } else {
        setSelectedTab(index);
        setHeroImage(heroImages[index]);
      }
      setSelectedOpenTab(null);
    },
    [selectedTab]
  );
  const selectedOpenTabHandler = useCallback(
    (index) => {
      const types = ["Farm", "Factory", "Investor"];

      setServicesData([
        {
          name: "Services",
          description:
            "Encompasses services provided directly by the center to clients, such as financial support, training, and technical consultations, aiming to enhance and improve the date palm farming and date production sector.",
          index: 0,
          data:
            selectedTab !== null
              ? data
                  ?.filter(
                    (item) =>
                      item.type === types[selectedTab] && item.tabs.length === 2
                  )
                  .map((item) => ({
                    title: t(item.name),
                    image: item.image,
                    url: `services/${item.name}`,
                  }))
              : [],
        },
        {
          name: "Knowledge",
          description:
            "Includes all knowledge and educational services, such as training courses, educational resources, and technical information, with the goal of improving date palm farming practices and promoting awareness and understanding.",
          index: 1,
          data:
            selectedTab !== null
              ? data
                  ?.filter(
                    (item) =>
                      item.type === types[selectedTab] &&
                      item.tabs.indexOf("KNOWLEDGE") + 1
                  )
                  .map((item) => ({
                    title: t(item.name),
                    image: item.image,
                    url: `services/${item.name}`,
                  }))
              : [],
        },
        {
          name: "Support",
          description:
            "Relates to services provided through partners or collaborative programs, including practical support for farmers and operators in the date palm farming sector.",
          index: 2,
          data:
            selectedTab !== null
              ? data
                  ?.filter(
                    (item) =>
                      item.type === types[selectedTab] &&
                      item.tabs.indexOf("SUPPORT") + 1
                  )
                  .map((item) => ({
                    title: t(item.name),
                    image: item.image,
                    url: `services/${item.name}`,
                  }))
              : [],
        },
      ]);
      setSelectedOpenTab(index);
    },
    [selectedTab, data]
  );

  const NewsItem = ({ item }) => {
    return (
      <li>
        <div className="rounded-image-container">
          <img src={item.image} alt="last-news1" id="lastNews-image" />
        </div>
        <h3 className="bold">
          {/* {t(`${item.title}`)} */}
          {/* {t(item.title)} */}
        </h3>
        <span className="medium">
          <img src="/assets/images/calendar.png" alt="calendar" />
          {t(`${item.date}`)}
        </span>
        {/* <p className={`medium`}>{t(item.news)}</p> */}
        <p className={`medium`}>{t(`${item.tweet}`)}</p>
        {item.title.split(" ").length > 10 && item.news.length > 150 && (
          <button className="bold">
            {t("Read More")}
            <FaArrowRight size={16} />
          </button>
        )}
      </li>
    );
  };
  const getDatesHandler = useCallback(
    (selectedCalendarTab) => {
      let { month, year } = calendar;
      let totalDays = [],
        days = [
          {
            day: "Sunday",
            dates: [],
          },
          {
            day: "Monday",
            dates: [],
          },
          {
            day: "Tuesday",
            dates: [],
          },
          {
            day: "Wednesday",
            dates: [],
          },
          {
            day: "Thursday",
            dates: [],
          },
          {
            day: "Friday",
            dates: [],
          },
          {
            day: "Saturday",
            dates: [],
          },
        ];
      if ((0 === year % 4 && 0 !== year % 100) || 0 === year % 400) {
        totalDays = leapYearDays[month];
      } else {
        totalDays = nonLeapYearDays[month];
      }
      let day = new Date(year, month).getDay();
      if (day !== 0) {
        for (let i = 0; i < day; i++) {
          days[i].dates.push({});
        }
      }
      const eventMonth = Object.keys(events2024);
      for (let i = 1; i <= totalDays; i++) {
        const currentMonth = months[month];
        const isMonthIncluded = eventMonth.includes(currentMonth);
        console.log("🚀 ~ getDatesHandler ~ isMonthIncluded:", isMonthIncluded);
        const matchingEvent = isMonthIncluded
          ? events2024[currentMonth].find((event) => event.day === i)
          : null;

        if (isMonthIncluded && selectedCalendarTab === 1) {
          days[day].dates.push({
            date: i,
            event:
              months[month] === (isMonthIncluded ? currentMonth : false) &&
              i <= 31
                ? {
                    date: `${months[month]} ${i}`,
                    time: t(`${matchingEvent.name}`),
                  }
                : {},
          });
        } else {
          days[day].dates.push({
            date: i,
          });
        }
        day++;
        if (day > 6) {
          day = 0;
        }
      }
      setCalendar({
        year,
        month,
        days,
      });
    },
    [calendar]
  );

  useEffect(() => {
    getDatesHandler(selectedCalendarTab);
  }, [calendar.year, calendar.month, selectedCalendarTab]);

  const toggleNewsExpand = (news) => {
    setExpandedNews((prevExpandedNews) => ({
      ...prevExpandedNews,
      [news]: !prevExpandedNews[news],
    }));
  };
  // const handleEmailClick = () => {
  //   window.location.href = 'mailto:info@ncpd.org.sa';
  // };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const calendarHandler = useCallback(
    (isIncrease) => {
      let month = calendar.month,
        year = calendar.year;
      if (isIncrease) {
        if (month === 11) {
          month = 0;
          year += 1;
        } else {
          month += 1;
        }
      } else {
        if (month === 0) {
          month = 11;
          year -= 1;
        } else {
          month -= 1;
        }
      }
      setCalendar({
        ...calendar,
        month,
        year,
      });
    },
    [calendar]
  );

  return (
    <div className="container home">
      <section className="hero" id="hero">
        <div className="background">
          <img alt="hero" src={heroImage} />
        </div>
        <div className="wrapper">
          <div className="content-box">
            <div
              className={selectedTab === null ? "headers active" : "headers"}
            >
              <h1 className="bold">{t("heading")}</h1>
              <p className="medium">
                {/* {t("Subheading")} */}

                {window.innerWidth < 576 ? (
                  <>{t("Subheading")}</>
                ) : (
                  <>
                    {t("Subheading1")}
                    <br />
                    {t("Subheading2")}
                    <br />
                    {t("Subheading3")}
                    <br />
                  </>
                )}
                {/* {t("Subheading1")}<br/>
                {t("Subheading2")}<br/>
                {t("Subheading3")}<br/> */}
              </p>
            </div>
            <ul
              className={
                selectedTab !== null
                  ? selectedOpenTab !== null
                    ? "cards active open"
                    : "cards active"
                  : "cards"
              }
            >
              {servicesData.map((item, index) => {
                if (selectedOpenTab !== index) {
                  // console.log("item=>", item, "index", index)
                  return (
                    <li>
                      <img src="/assets/images/hero-card.png" alt="card" />
                      <h2 className="bold">{t(`${item.name}`)}</h2>
                      <p className="medium">{t(`${item.description}`)}</p>
                      <button
                        className="bold"
                        onClick={() => selectedOpenTabHandler(item.index)}
                      >
                        {t("read more")}
                        <div className="home__arrow">
                          <FaArrowRight size={16} />
                        </div>
                      </button>
                      <div className="sub-cards">
                        {item.data.map((el) => (
                          <div
                            className="sub-card"
                            onClick={() => navigate(el.url)}
                          >
                            <img src={el.image} alt="sub-card" />
                            <div className="info">
                              <h3 className="bold">{t(`${el.title}`)}</h3>
                              <p className="medium">{t(`${el.description}`)}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </li>
                  );
                } else {
                  return (
                    <li key={index} className="active">
                      <img src="/assets/images/hero-card.png" alt="card" />
                      <h2 className="bold">{t(`${item.name}`)}</h2>
                      <p className="medium">{t(`${item.description}`)}</p>
                      <button className="bold">
                        {t("read-more")}
                        <FaArrowRight size={16} />
                      </button>
                      <div className="sub-cards">
                        {/* {console.log(item.data)} */}
                        {item.data.map((el) => (
                          <div
                            className="sub-card"
                            onClick={() => navigate(el.url)}
                          >
                            <img src={el.image} alt="sub-card" />
                            <div className="info">
                              <h3 className="bold">{t(`${el.title}`)}</h3>
                              <p className="medium">{t(`${el.description}`)}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
          <ul className="action-buttons">
            <li>
              <button
                onClick={() => selectedTabHandler(0)}
                className={selectedTab === 0 ? "bold active" : "bold"}
                id="home_tab_category"
              >
                <img
                  src={
                    selectedTab === 0
                      ? "/assets/images/farmer-dark.png"
                      : "/assets/images/farmer.png"
                  }
                  alt="farmer"
                />
                <p className="f-16">{t("Home_Im_afarmer")}</p>
              </button>
            </li>
            <li>
              <button
                onClick={() => selectedTabHandler(1)}
                className={selectedTab === 1 ? "bold active" : "bold"}
                id="home_tab_category"
              >
                <img
                  src={
                    selectedTab === 1
                      ? "/assets/images/factory-dark.png"
                      : "/assets/images/industry.png"
                  }
                  alt="industry"
                />
                {/* {t("Home_We_r_afactory")} */}
                <p className="f-16">{t("I am manufacturer")}</p>
              </button>
            </li>
            <li>
              <button
                onClick={() => selectedTabHandler(2)}
                className={selectedTab === 2 ? "bold active" : "bold"}
                id="home_tab_category"
              >
                <img
                  src={
                    selectedTab === 2
                      ? "/assets/images/investor-dark.png"
                      : "/assets/images/investor.png"
                  }
                  alt="investor"
                />
                <p className="f-16">{t("Home_Iam_a_investor")}</p>
              </button>
            </li>
          </ul>
        </div>
      </section>
      <section className="calendar content">
        <div className="container-wrapper">
          <h2 className="bold f-40">{t("upcoming")}</h2>
          <p className="medium f-20">{t("upcoming_events")}</p>
          <div className="tabs">
            {/* <button
              onClick={() => selectedCalendarTabHandler(1)}
              className={
                selectedCalendarTab === 1 ? "primary" : "primary outline"
              }
            >
              {t("Events")}
            </button> */}
            <button
              onClick={() => selectedCalendarTabHandler(1)}
              className={
                selectedCalendarTab === 1 ? "primary" : "primary outline"
              }
            >
              {t("Agricultural Dates")}
            </button>
            <button
              onClick={() => selectedCalendarTabHandler(2)}
              className={
                selectedCalendarTab === 2 ? "primary" : "primary outline"
              }
            >
              {t("Globally and locally Exhibition Participation")}
            </button>
          </div>
          <div className="calendar-view">
            <h4
              className="bold"
              style={{ fontFamily: "Arial", fontSize: "16", fontWeight: "600" }}
            >
              {t(`${months[calendar.month]}`)} {calendar.year}
            </h4>
            <ul>
              {calendar?.days?.length > 0 &&
                calendar.days.map((day) => (
                  <li>
                    <h3 className="bold f-14">{t(`${day.day}`)}</h3>
                    {day.dates.map((date) => (
                      <div
                        className={
                          date?.date
                            ? date.event?.date
                              ? "date outline active "
                              : "date outline"
                            : "date"
                        }
                      >
                        {date?.event?.date ? (
                          <div className="homeToolTip">
                            <span className="medium f-14">
                              {/* {t(`${date.event.date.split(" ")[0]}`)}{" "} */}
                              <div
                                style={{
                                  fontFamily: "Arial",
                                  fontWeight: "bold",
                                }}
                              >
                                {t(`${date.event.date.split(" ")[1]}`)}
                              </div>
                            </span>
                            {/* <span className="medium f-14 ">
                              {date.event.time % 60 === 0
                                ? `${Number(date.event.time / 60)} ${t(
                                    "hours"
                                  )}`
                                : date.event.time}
                                
                            </span> */}

                            {/* {console.log(
                              "====>",
                              date.event.date.split(" ")[0]
                            )} */}
                            {date.event.date.split(" ")[0] === "January" && (
                              <span className="tooltiptext">
                                <ol className="toolTipList">
                                  <li> 1. {t("events.january.eventName1")}</li>
                                  <li> 2. {t("events.january.eventName2")}</li>
                                  <li> 3. {t("events.january.eventName3")}</li>
                                  <li> 4. {t("events.january.eventName4")}</li>
                                  <li> 5. {t("events.january.eventName5")}</li>
                                  <li> 6. {t("events.january.eventName6")}</li>
                                  <li> 7. {t("events.january.eventName7")}</li>
                                  <li> 8. {t("events.january.eventName8")}</li>
                                </ol>
                              </span>
                            )}
                            {date.event.date.split(" ")[0] === "February" && (
                              <span className="tooltiptext">
                                <ol className="toolTipList">
                                  <li> 1. {t("events.february.eventName1")}</li>
                                  <li> 2. {t("events.february.eventName2")}</li>
                                  <li> 3. {t("events.february.eventName3")}</li>
                                  <li> 4. {t("events.february.eventName4")}</li>
                                  <li> 5. {t("events.february.eventName5")}</li>
                                  <li> 6. {t("events.february.eventName6")}</li>
                                  <li> 7. {t("events.february.eventName7")}</li>
                                  <li> 8. {t("events.february.eventName8")}</li>
                                </ol>
                              </span>
                            )}
                          </div>
                        ) : (
                          <span
                            className="medium f-14"
                            style={{
                              fontFamily: "Arial",
                              fontSize: "16",
                              fontWeight: "500",
                            }}
                          >
                            {date.date}
                          </span>
                        )}
                      </div>
                    ))}
                  </li>
                ))}
            </ul>
            <div className="next-previous">
              <button
                className={
                  calendar.year === new Date().getFullYear()
                    ? calendar.month === new Date().getMonth()
                      ? "primary outline"
                      : "primary"
                    : "primary"
                }
                onClick={
                  calendar.year === new Date().getFullYear() &&
                  calendar.month === new Date().getMonth()
                    ? null
                    : () => calendarHandler(false)
                }
              >
                <FaArrowLeft size={16} />
              </button>
              <button onClick={() => calendarHandler(true)} className="primary">
                <FaArrowRight size={16} />
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="last-news">
        <div className="container-wrapper">
          <h2 className="bold">{t("latest-news")}</h2>
          <p className="medium">
            {t("Media_center_news_Enhance")}
            {/* Enhance your cultural ability by learning more news, practical */}
            {/* <br /> */}
            {/* articles and marketing moment by moment */}
          </p>
          <ul>
            {/* {newsContent
              .sort(
                (a, b) =>
                  new Date(b.date).getTime() - new Date(a.date).getTime()
              )
              .slice(0, 4)
              .map((item, index) => (
                <Link to={`/media-center/news/${item.id}`}>
                  <NewsItem key={index} item={item} />
                </Link>
              ))} */}

            {/* {tweets?.map((item, index) => (
              <Link to={item.link} target="_blank">
                <NewsItem key={index} item={item} />
              </Link>
            ))} */}
            {/* {tweets?.map((item, index) => ( */}
            <div data-theme="light" className="twitter-widget">
              <Tweet id="1749321905033658570" />
              <Tweet id="1749051008666259907" />
              <Tweet id="1746579900960534994" />
            </div>
            {/* ))} */}
          </ul>
          <Link to="https://twitter.com/NCPD_SA" target="_blank">
            <button className="primary">{t("MORE NEWS")}</button>
          </Link>
        </div>
      </section>
      <section className="most-visted-pages content">
        <div className="container-wrapper">
          <div className="headers">
            <h2 className="bold">{t("Home_Page_Most_visited_services")}</h2>
            {/* <p className="medium">
              Lorem ipsum dolor sit amet consectetur. Accumsan elit felis id
              sagittis platea at. Et maecenas volutpat ipsum aenean velit in
              dolor vitae integer. Posuere nec nunc in donec.
            </p>
            <button className="bold">
              Read More
              <FaArrowRight size={16} />
            </button> */}
          </div>
          <ul>
            <Link to="/services/service_agriculture">
              <li>
                <span className="bold f-24">{t("service_agriculture")}</span>
                <p className="medium">{t("service1.introduction_content")}</p>
              </li>
            </Link>
            <Link to="/services/Date_Purchase">
              <li>
                <span className="bold f-24">{t("Date_Purchase")}</span>
                <p className="medium">{t("service2.introduction_content")}</p>
              </li>
            </Link>

            <Link to="/services/Service_Saudi_Dates_Mark_for_SFarmers">
              <li>
                <span className="bold f-24">
                  {t("Service_Saudi_Dates_Mark_for_SFarmers")}
                </span>
                <p className="medium f-20 color-secondary">
                  {t("service3.introduction_content")}
                </p>
              </li>
            </Link>
          </ul>
        </div>
      </section>
      <section className="map-section content">
        <div className="container-wrapper">
          <div className="info">
            <h2 className="bold">{t("Home_Contact_US")}</h2>
            <span
              className="medium f-14"
              onClick={handleAddressClick}
              style={{ cursor: "pointer" }}
            >
              <GrLocation size={14} />
              {t("Al-Dahi, Hittin, Riyadh")}
              <p style={{ fontFamily: "Arial", fontWeight: "bold" }}>13512</p>
            </span>
            <Link to="tel:8003010055">
              <span
                className="medium f-14"
                style={{ fontFamily: "Arial", fontWeight: "bold" }}
              >
                <BsTelephone size={14} />
                8003010055
              </span>
            </Link>
            <Link to="mailto:info@ncpd.org.sa">
              <span className="medium f-14">
                <CiMail size={14} />
                info@ncpd.org.sa
              </span>
            </Link>
            <Link to="/contact-us">
              <button className="primary">{t("CONTACT_US")}</button>
            </Link>
          </div>
          <div className="map-wrapper">
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={1}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                <Marker position={center} />
              </GoogleMap>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
