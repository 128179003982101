import React from "react";
import { Link } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";
import "./media.css";

import {
  KingdomExport_statistics,
  nakheelAndKingdomFarms_statistics,
  datesProductionGlobally_statistics,
} from "../../content/report";
import { useTranslation } from "react-i18next";

const Statistics = () => {
  const {t} = useTranslation();
  const downloadReport = (filePath, folder) => {
    const pdfUrl = `/assets/pdf/statistics/${folder}/${filePath}`;
    const fileName = pdfUrl.substring(pdfUrl.lastIndexOf("/") + 1);
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfUrl;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/"><h3>{t("Home")}</h3></Link>
          <FaAnglesRight size={14} />
          <Link to="/media-center/faqs"><h3>{t("mediaCenter")}</h3></Link>
          <FaAnglesRight size={14} />
          <span>{t("Media_center_Statistics")}</span>
        </div>
      </div>
      <div className="content media-statistics">
        <div className="container-wrapper">
          <h1 className="bold f-40 color-primary">{t("Media_center_Statistics")}</h1>
          <p className="medium f-14 color-gray">
            {t("Media_center_news_Enhance")}
          </p>
          <section>
            <div className="statistics">
              <h2 className="bold f-28 color-primary statistic__label">
                {t("Media_center_Statistics_kingdom")}
              </h2>
              <ul>
                {KingdomExport_statistics.map((statistic) => (
                  <li>
                    <div className="statistic">
                      <div className="image-wrapper">
                        <img src="/assets/images/report.png" alt="report" />
                      </div>
                      <span className="color-primary medium f-20" >
                      {t(`${statistic.title}`)} {t(`${statistic.date}`)}
                      </span>
                    </div>
                    <button className="primary" onClick={() => downloadReport(statistic.filePath, "kingdom")}>{t("DOWNLOAD")}</button>
                  </li>
                ))}
                {/* <li>
                  <div className="statistic">
                    <div className="image-wrapper">
                      <img src="/assets/images/report.png" alt="report" />
                    </div>
                    <span className="color-primary medium f-20">year 2022</span>
                  </div>
                  <button className="primary">DOWNLOAD</button>
                </li>
                <li>
                  <div className="statistic">
                    <div className="image-wrapper">
                      <img src="/assets/images/report.png" alt="report" />
                    </div>
                    <span className="color-primary medium f-20">year 2022</span>
                  </div>
                  <button className="primary">DOWNLOAD</button>
                </li>
                <li>
                  <div className="statistic">
                    <div className="image-wrapper">
                      <img src="/assets/images/report.png" alt="report" />
                    </div>
                    <span className="color-primary medium f-20">year 2022</span>
                  </div>
                  <button className="primary">DOWNLOAD</button>
                </li> */}
              </ul>
            </div>
            <div className="statistics">
              <h2 className="bold f-28 color-primary statistic__label">
                {t("Media_center_Statistics_Nakheel")}
              </h2>
              <ul>
                {nakheelAndKingdomFarms_statistics.map((statistic) => (
                  <li>
                    <div className="statistic">
                      <div className="image-wrapper">
                        <img src="/assets/images/report.png" alt="report" />
                      </div>
                      <span className="color-primary medium f-20 " >
                      {t(`${statistic.title}`)} {t(`${statistic.date}`)}
                      </span>
                    </div>
                    <button className="primary" onClick={() => downloadReport(statistic.filePath, "nakheel")}>{t("DOWNLOAD")}</button>
                  </li>
                ))}
                {/* <li>
                  <div className="statistic">
                    <div className="image-wrapper">
                      <img src="/assets/images/report.png" alt="report" />
                    </div>
                    <span className="color-primary medium f-20">year 2022</span>
                  </div>
                  <button className="primary">DOWNLOAD</button>
                </li>
                <li>
                  <div className="statistic">
                    <div className="image-wrapper">
                      <img src="/assets/images/report.png" alt="report" />
                    </div>
                    <span className="color-primary medium f-20">year 2022</span>
                  </div>
                  <button className="primary">DOWNLOAD</button>
                </li>
                <li>
                  <div className="statistic">
                    <div className="image-wrapper">
                      <img src="/assets/images/report.png" alt="report" />
                    </div>
                    <span className="color-primary medium f-20">year 2022</span>
                  </div>
                  <button className="primary">DOWNLOAD</button>
                </li> */}
              </ul>
            </div>
            <div className="statistics">
              <h2 className="bold f-28 color-primary statistic__label">
                {t("Media_center_Statistics_Dates")}
              </h2>
              <ul>
                {datesProductionGlobally_statistics.map((statistic) => (
                  <li>
                    <div className="statistic">
                      <div className="image-wrapper">
                        <img src="/assets/images/report.png" alt="report" />
                      </div>
                      <span className="color-primary medium f-20">
                        {t(`${statistic.title}`)}
                      </span>
                    </div>
                    <button className="primary" onClick={() => downloadReport(statistic.filePath, "globally")}>{t("DOWNLOAD")}</button>
                  </li>
                ))}
                {/* <li>
                  <div className="statistic">
                    <div className="image-wrapper">
                      <img src="/assets/images/report.png" alt="report" />
                    </div>
                    <span className="color-primary medium f-20">year 2022</span>
                  </div>
                  <button className="primary">DOWNLOAD</button>
                </li>
                <li>
                  <div className="statistic">
                    <div className="image-wrapper">
                      <img src="/assets/images/report.png" alt="report" />
                    </div>
                    <span className="color-primary medium f-20">year 2022</span>
                  </div>
                  <button className="primary">DOWNLOAD</button>
                </li> */}
                {/* <li>
                  <div className="statistic">
                    <div className="image-wrapper">
                      <img src="/assets/images/report.png" alt="report" />
                    </div>
                    <span className="color-primary medium f-20">year 2022</span>
                  </div>
                  <button className="primary">DOWNLOAD</button>
                </li> */}
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
