import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import "./joinus.css";
import { toast } from "react-toastify";

const JoinUs = () => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState({
    name: "",
    nationality: "",
    birthPlace: "",
    DOB: "",
    phone: "",
    email: "",
    qualification: "",
    generalSpecialization: "",
    specialization: "",
    gradePoint: "",
    graduatedUniversity: "",
    professionalCertification: "",
    yearsOfExperience: "",
  });
  const [gender, setGender] = useState("Male");
  const [selectedFile, setSelectedFile] = useState({
    biography: "",
    academicQualification: "",
    academicRecord: "",
    courses: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = [
      "name",
      "nationality",
      "birthPlace",
      "DOB",
      "phone",
      "email",
      "qualification",
      "generalSpecialization",
      "specialization",
      "gradePoint",
      "graduatedUniversity",
      "professionalCertification",
      "yearsOfExperience",
    ];
    const requiredFiles = [
      "biography",
      "academicQualification",
      "academicRecord",
      "courses",
    ];
    const isFormValid = requiredFields.every(
      (field) => inputValue[field].trim() !== ""
    );
    const isFileSelected = requiredFiles.every(
      (field) => selectedFile[field].trim() !== ""
    );

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!emailRegex.test(inputValue.email)) {
      toast.error("Email is not valid.");
      return;
    }

    const phoneRegex = /^\d{8,15}$/;
    if (!phoneRegex.test(inputValue.phone)) {
      toast.error("Phone number is not valid.");
      return;
    }

    if (!isFormValid) {
      toast.error("Please fill in all the required fields.");
      return;
    } else if (!gender) {
      toast.error("Please Gender in all the required fields.");
      return;
    } else if (!isFileSelected) {
      toast.error("Please select all required files.");
      return;
    } else {
      toast.success("Form submitted successfully.");
      return;
    }
  };

  // Function to handle file change
  const handleChange = (e) => {
    const name = e.target.name;
    if (e.target.type === "file") {
      const files = e.target.files;
      setSelectedFile((prev) => ({
        ...prev,
        [name]: files[0].name,
      }));
    } else {
      const value = e.target.value;
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    // console.log("inputValue=>", inputValue)
  };

  const handleGenderChange = (e) => {
    e.preventDefault();
    setGender(e.target.value);
    // console.log("gender", gender)
  };
  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/">
            <h3>{t("Home")}</h3>
          </Link>
          <FaAnglesRight size={14} />
          <span>{t("Join Us")}</span>
        </div>
      </div>
      <div className="content joinus">
        <div className="container-wrapper">
          <h1 className="bold f-40 color-primary">{t("Join Us")}</h1>
          <div className="form-wrapper">
            <form onSubmit={handleSubmit}>
              <h2 className="medium f-24 color-primary">
                {t("Join us at the National Palm")}
              </h2>
              <section>
                <h3 className="medium f-20 color-primary">
                  {t("Personal data")}
                </h3>
                <ul>
                  <li>
                    <div className="inputs-container">
                      <input
                        name="name"
                        placeholder={`${t("Name")}*`}
                        onChange={handleChange}
                      />
                      <input
                        name="nationality"
                        placeholder={`${t("Nationality")}*`}
                        onChange={handleChange}
                      />
                    </div>
                  </li>
                  <li>
                    <h4 className="medium f-14 color-primary">
                      {t("Gender")}
                      <span className="bold f-14">*</span>
                    </h4>
                    <div className="checkboxes-container">
                      <label
                        className="medium f-14 color-primary"
                        onClick={() => setGender("Male")}
                      >
                        <input
                          value={gender === "Male" ? "✓" : ""}
                          onChange={handleGenderChange}
                          name="gender"
                          readOnly
                          style={{
                            caretColor: "transparent",
                            animation: "none",
                          }}
                        />
                        {t("Male")}
                      </label>
                      <label
                        className="medium f-14 color-primary"
                        onClick={() => setGender("Female")}
                      >
                        <input
                          value={gender === "Female" ? "✓" : ""}
                          onChange={handleGenderChange}
                          name="gender"
                          readOnly
                          style={{
                            caretColor: "transparent",
                            animation: "none",
                          }}
                        />
                        {t("Female")}
                      </label>
                    </div>
                  </li>
                  <li>
                    <div
                      className="inputs-container"
                      style={{ position: "relative" }}
                    >
                      <input
                        name="birthPlace"
                        placeholder={`${t("Place of birth")}*`}
                        onChange={handleChange}
                      />
                      <div className="inputCalender1">
                        <label className="medium f-14 color-primary">{t("Date of birth")}*</label>
                        <input
                          name="DOB"
                          type="date"
                          placeholder={`${t("Date of birth")}*`}
                          onChange={handleChange}
                          className="inputCalender2"
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="inputs-container">
                      <input
                        name="phone"
                        type="number"
                        placeholder={`${t("Phone number")}*`}
                        onChange={handleChange}
                      />
                      <input
                        name="email"
                        type="email"
                        placeholder={`${t("E-mail")}*`}
                        onChange={handleChange}
                      />
                    </div>
                  </li>
                </ul>
              </section>
              <section>
                <h3 className="medium f-20 color-primary">
                  {t("Academic data")}
                </h3>
                <ul>
                  <li>
                    <div className="inputs-container">
                      <input
                        name="qualification"
                        placeholder={`${t("Academic qualification")}*`}
                        onChange={handleChange}
                      />
                      <input
                        name="generalSpecialization"
                        placeholder={`${t("General specialization")}*`}
                        onChange={handleChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="inputs-container">
                      <input
                        name="specialization"
                        type="text"
                        placeholder={`${t("Specialization")}*`}
                        onChange={handleChange}
                      />
                      <input
                        name="gradePoint"
                        type="number"
                        placeholder={`${t("Grade point average")}*`}
                        onChange={handleChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="inputs-container">
                      <input
                        placeholder={`${t(
                          "University from which he graduated"
                        )}*`}
                        name="graduatedUniversity"
                        onChange={handleChange}
                      />
                      <input
                        placeholder={`${t("Professional Certifications")}*`}
                        name="professionalCertification"
                        onChange={handleChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="inputs-container">
                      <input
                        name="yearsOfExperience"
                        type="number"
                        placeholder={`${t("Years of experience")}*`}
                        onChange={handleChange}
                      />
                    </div>
                  </li>
                </ul>
              </section>
              <section className="files">
                <h3 className="medium f-20 color-primary">
                  {t("Attachments")}
                </h3>
                <ul>
                  <li>
                    <h4 className="medium f-14 color-primary">
                      {t("Biography")}
                      <span className="bold f-14">*</span>
                    </h4>
                    <div className="file-input">
                      <label
                        className="medium f-14 color-primary"
                        type="button"
                      >
                        <input
                          type="file"
                          name="biography"
                          onChange={handleChange}
                        />
                        {t("Select files")}
                      </label>
                      <p className="medium f-14 color-gray">
                        {selectedFile.biography
                          ? `${selectedFile.biography}`
                          : "Files not selected"}
                        {/* {t("Files not selected")} */}
                      </p>
                    </div>
                  </li>
                  <li>
                    <h4 className="medium f-14 color-primary">
                      {t("Academic qualification")}
                      <span className="bold f-14">*</span>
                    </h4>
                    <div className="file-input">
                      <label
                        className="medium f-14 color-primary"
                        type="button"
                      >
                        <input
                          type="file"
                          name="academicQualification"
                          onChange={handleChange}
                        />
                        {t("Select files")}
                      </label>
                      <p className="medium f-14 color-gray">
                        {selectedFile.academicQualification
                          ? `${selectedFile.academicQualification}`
                          : "Files not selected"}
                        {/* {t("Files not selected")} */}
                      </p>
                    </div>
                  </li>
                  <li>
                    <h4 className="medium f-14 color-primary">
                      {t("Academic record")}
                      <span className="bold f-14">*</span>
                    </h4>
                    <div className="file-input">
                      <label
                        className="medium f-14 color-primary"
                        type="button"
                      >
                        <input
                          type="file"
                          name="academicRecord"
                          onChange={handleChange}
                        />
                        {t("Select files")}
                      </label>
                      <p className="medium f-14 color-gray">
                        {selectedFile.academicRecord
                          ? `${selectedFile.academicRecord}`
                          : "Files not selected"}
                        {/* {t("Files not selected")} */}
                      </p>
                    </div>
                  </li>
                  <li>
                    <h4 className="medium f-14 color-primary">
                      {t("Professional certificates and courses")}
                      <span className="bold f-14">*</span>
                    </h4>
                    <div className="file-input">
                      <label
                        className="medium f-14 color-primary"
                        type="button"
                      >
                        <input
                          type="file"
                          name="courses"
                          onChange={handleChange}
                        />
                        {t("Select files")}
                      </label>
                      <p className="medium f-14 color-gray">
                        {selectedFile.courses
                          ? `${selectedFile.courses}`
                          : "Files not selected"}
                        {/* {t("Files not selected")} */}
                      </p>
                    </div>
                  </li>
                </ul>
              </section>
              <button className="primary">{t("Home_Send")} </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
