// let initialState = "ARABIC";
let lang = localStorage.getItem("language") || "ar"
const languageReducer = (state = lang, { type, payload="ar" }) => {
  switch (type) {
    case "SET_LANGUAGE": {
      return (state = payload);
    }
    default: {
      return state;
    }
  }
};

export default languageReducer;
