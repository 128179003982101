export const partnerDetailsData = [
  {
    partnerId: "sidf",
    partner: "sidf_partner",
    description: "sidf_description",
    image: "sidf.png",
    services: [
      {
        title: "service_title1",
        description: "service_description1",
        link: "https://www.sidf.gov.sa/en/ServicesforInvestors/Products/Pages/LoanProcess.aspx",
      },
      {
        title: "service_title2",
        description: "service_description2",
        link: "https://www.sidf.gov.sa/en/ServicesforInvestors/Products/Pages/WorkingCapital.aspx",
      },
      {
        title: "service_title3",
        description: "service_description3",
        link: "https://www.sidf.gov.sa/en/ServicesforInvestors/Programs/Pages/Afaq.aspx",
      },
    ],
    requirements: [
      {
        title: "Financing Key Requirements",
        data: [
          "National ID",
          "Commercial Registration",
          "Articles of Association",
          "Fill No-objection Form for Information Exchange",
        ],
      },
    ],
  },
  {
    partnerId: "saudiExim",
    partner: "saudiExim_partner",
    description: "saudiExim_description",
    image: "saudiExim.jpg",
    pdfs: [
      {
        title: "Saudi Export-Import Bank",
        url: "https://ncpd.gov.sa/elnakhel/public/storage/omissives/11652296261640607833_%D8%A8%D9%86%D9%83%20%D8%A7%D9%84%D8%AA%D8%B5%D8%AF%D9%8A%D8%B1%20%D9%88%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D9%8A%D8%B1%D8%A7%D8%AF%20%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A.pdf",
      },
    ],
  },
  {
    partnerId: "adf",
    partner: "صندوق التنمية الزراعية :",
    description:
      "تأسس البنك الزراعي العربي السعودي بموجب المرسوم الملكي رقم (58) وتاريخ 3/12/1382هـ ليكون مؤسسة ائتمانية حكومية متخصصة في تمويل مختلف مجالات النشاط الزراعي في جميع مناطق المملكة، للمساعدة في تنمية القطاع الزراعي ورفع كفاءته الإنتاجية باستخدام أفضل الأساليب العلمية والتقنية الحديثة، وذلك عن طريق تقديم قروض ميسرة بدون فوائد للمزارعين لتأمين ما يلزم هذا النشاط مثل المكائن والمضخات والآلات الزراعية ومعدات تربية الأبقار والدواجن والأغنام ومعدات تربية النحل والأسماك وغيرها.",
    image: "adf.png",
    services: [
      {
        title: "قروض صندوق التنمية الزراعية",
        description: "",
        link: "",
      },
    ],
    requirements: [
      {
        title: "General requirements for specialized agricultural projects",
        data: [
          "Feasibility study + financial study (financial statements that reflect all the data contained in the study)",
          "An original and copy of the ministerial license + an original of the project evaluation form attached to the license.",
          "An original and copy of the project’s land title (land deed or lease contract)",
          "The origin and image of the national identity",
        ],
      },
      {
        title:
          "Providing the necessary guarantees and their types are as follows",
        data: [
          "Bank guarantee for the entire loan.",
          "Real estate guarantee (commercial, residential or agricultural).",
          "Guarantee of project assets.",
          "Personal guarantee",
          "Price offers and implementation contracts, with their translation for all required purposes.",
          "Engineering drawings from a dark engineering office.",
        ],
      },
    ],
  },
  {
    partnerId: "sdb",
    partner: "sdb_partner",
    description: "sdb_description",
    image: "sdb.png",
    services: [
      {
        title: "sdb_service_title1",
        description: "sdb_service_description1",
        link: "https://www.sdb.gov.sa/en-us ",
      },
    ],
  },
  {
    partnerId: "monshaat",
    partner: "monshaat_partner",
    description: "monshaat_description",
    image: "monshaat.png",
    services: [
      {
        title: "monshaat_service_title1",
        description: "monshaat_service_description1",
        link: "https://www.monshaat.gov.sa/ar/datesacc",
      },
      {
        title: "monshaat_service_title2",
        description: "monshaat_service_description2",
        link: "https://mazaya.monshaat.gov.sa/ar",
      },
      {
        title: "monshaat_service_title3",
        description: "monshaat_service_description3",
        link: "https://www.monshaat.gov.sa/ssc",
      },
      {
        title: "monshaat_service_title4",
        description: "monshaat_service_description4",
        link: "https://registration.goodat.co/",
      },
      {
        title: "monshaat_service_title5",
        description: "monshaat_service_description5",
        link: "https://tamweel.monshaat.gov.sa/",
      },
      {
        title: "monshaat_service_title6",
        description: "monshaat_service_description6",
        link: "https://academy.monshaat.gov.sa/",
      },
      {
        title: "monshaat_service_title7",
        description: "monshaat_service_description7",
        link: "https://tomoh.sa/"
      },
    ],
  },
];
