import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaAnglesRight, FaArrowRight } from "react-icons/fa6";
import "./media.css";
import { useTranslation } from "react-i18next";
import { createEvent } from "ics";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";

const Events = () => {
  const [selectedDate, setSelectedDate] = useState(new Date("04/01/2021"));
  const { t } = useTranslation();
  const handleAddToCalendar = () => {
    const event = {
      title: "NCPD New Event",
      description:
        "The National Center for Palms and Dates launches the Dates Market exhibition at the front of Riyadh. Which is considered a unique experience in shopping and tasting dates while driving around in the car, during the period 3-31-2021 AD to 4-5-2021 AD.This exhibition comes in implementation of the International Year of Fruits and Vegetables 2021 AD, and in great cooperation between the National Center for Palms and Dates, the Ministry of Environment, Water and Agriculture, the Ministry of Culture, and a number of relevant sectors. In addition to the success partners in the sector from date companies.",
      location: "Al-Dahi, Hittin, Riyadh 13512",
      start: selectedDate,
      end: new Date(selectedDate.getTime() + 60 * 60 * 1000),
    };

    createEvent(event, (error, value) => {
      if (!error) {
        const blob = new Blob([value], { type: "text/calendar;charset=utf-8" });
        saveAs(blob, "event.ics");
      }
    });

    // Open the Google Calendar link in a new tab
    const googleCalendarUrl = `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(
      event.title
    )}&dates=${encodeURIComponent(
      event.start.toISOString().replace(/-|:|\.\d+/g, "")
    )}/${encodeURIComponent(
      event.end.toISOString().replace(/-|:|\.\d+/g, "")
    )}&location=${encodeURIComponent(
      event.location
    )}&details=${encodeURIComponent(event.description)}`;

    window.open(googleCalendarUrl, "_blank");
  };

  function handleAppleCalendar(event) {
    if (!event) {
      toast.error("Invalid event.");
      return;
    }

    // Check if start and end properties exist
    const start = event.start instanceof Date ? event.start : new Date();
    const end =
      event.end instanceof Date
        ? event.end
        : new Date(start.getTime() + 60 * 60 * 1000); // Default to 1 hour duration

    const appleCalendarUrl = `
  BEGIN:VCALENDAR
  VERSION:2.0
  BEGIN:VEVENT
  SUMMARY:${encodeURIComponent(event.title || "Untitled Event")}
  DTSTART:${encodeURIComponent(
    start.toISOString().replace(/[-:]/g, "").replace(/\.\d+/g, "")
  )}
  DTEND:${encodeURIComponent(
    end.toISOString().replace(/[-:]/g, "").replace(/\.\d+/g, "")
  )}
  LOCATION:${encodeURIComponent(event.location || "")}
  DESCRIPTION:${encodeURIComponent(event.description || "")}
  END:VEVENT
  END:VCALENDAR
  `;

    // Create a Blob with the calendar data
    const blob = new Blob([appleCalendarUrl], {
      type: "text/calendar;charset=utf-8",
    });

    // Create a download link and trigger a click event
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "event.ics";
    link.click();
  }

  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/">
            <h3>{t("Home")}</h3>
          </Link>
          <FaAnglesRight size={14} />
          <Link to="/media-center/faqs">
            <h3>{t("mediaCenter")}</h3>
          </Link>
          <FaAnglesRight size={14} />
          <span>{t("Events")}</span>
        </div>
      </div>
      <div className="content media-events">
        <div className="container-wrapper">
          <h1 className="bold f-40 color-primary">{t("Events1")}</h1>
          <p className="medium f-14 color-gray">
            {t("Media_center_news_Enhance")}
          </p>
          <section className="details">
            <div className="image-wrapper">
              <img
                src="https://ncpd.gov.sa/elnakhel/public/storage/events/12883496271617261208_11468511991617191452_تصميم 1.jpg"
                alt="event"
              />
            </div>
            <div className="info">
              <div className="timings">
                <h2 className="bold f-20 color-primary">
                  {t("Media_center_Events_Details")}
                </h2>
                <span className="medium f-14 color-gray">
                  <img src="/assets/images/time.png" alt="time" />
                  {t("Duration")}: 1 {t("hour")}
                </span>
                <span className="medium f-14 color-gray">
                  <img src="/assets/images/calendar.png" alt="calendar" />
                  01 {t("April")} 2021
                </span>
                <h4 className="f-14 medium color-primary">
                  {t("Add to calendar")}
                </h4>
                <div className="add">
                  <span
                    className="medium f-14 color-gray"
                    style={{ cursor: "pointer" }}
                    onClick={handleAppleCalendar}
                  >
                    <img src="/assets/images/apple.png" alt="apple" />
                    {t("Apple")}
                  </span>
                  <span
                    className="medium f-14 color-gray"
                    style={{ cursor: "pointer" }}
                    onClick={handleAddToCalendar}
                  >
                    <img src="/assets/images/google.png" alt="google" />
                    {t("Google")}
                  </span>
                </div>
              </div>
              <div className="description">
                <h2 className="bold f-20 color-primary">
                  {t("Media_center_Description")}
                </h2>
                <p className="medium f-14 color-gray">
                  {t("Media_center_Description_The_National")}
                  <br />
                  <br />
                  {t("Media_center_Description_This_exhibition")}
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Events;
