import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import "./accordion.css";

const Accordion = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={open ? "accordion active" : "accordion"}>
      <div className="question">
        <p className="f-20 bold color-primary">{question}</p>
        <button onClick={() => setOpen(!open)}>
          {open ? <FaChevronUp size={20} /> : <FaChevronDown size={20} />}
        </button>
      </div>
      <div className="answer">
        <p className="medium f-14">{answer}</p>{" "}
      </div>
    </div>
  );
};

export default Accordion;
