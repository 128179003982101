import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import { useTranslation } from "react-i18next";
import { FaFacebookF, FaYoutube, FaLinkedinIn, FaTwitter  } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  const {t} = useTranslation(); 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className="footer">
      <div className="container-wrapper">
        <div className="logo">
          <Link to="/">
          <img src="/assets/images/dark-logo.png" alt="logo" onClick={scrollToTop} />
          </Link>
        </div>
        <div className="links-section">
          <h2 className="bold f-14">{t('Pages_that_interests_you')}</h2>
          <Link className="medium f-14" to="/">
            {t("Home")}
          </Link>
          <Link className="medium f-14" to="/about-ncpd/about-us">
            {t("About_Us")}
          </Link>
          <Link className="medium f-14" to="/about-ncpd/our-team">
            {t("Home Board of Directors")}
          </Link>
          <Link className="medium f-14" to="/contact-us">
            {t("Home_Contact_US")}
          </Link>
          <Link className="medium f-14" to="/media-center/news">
            {t("Home_News")}
          </Link>
          <Link className="medium f-14" to="/media-center/faqs">
            {t("Home_Media")}
          </Link>
          <Link className="medium f-14" to="/services">
            {t("Home_Initiatives")}
          </Link>
        </div>
        <div className="links-section">
          <h2 className="bold f-14">{t("Home_The_most")}</h2>
          <Link className="medium f-14" to="/">
            {t("Home_Farmer")}
          </Link>
          <Link className="medium f-14" to="/">
            {t("Home_factory")}
          </Link>
          <Link className="medium f-14" to="/">
            {t("Home_Local_markets")}
          </Link>
          <Link className="medium f-14" to="/">
            {t("Home_Ecommerce")}
          </Link>
          <Link className="medium f-14" to="/">
            {t("Home_Investors")}
          </Link>
        </div>
        <div className="lets-talk">
          <h2 className="bold">{t("Home_lets")}</h2>
          <form>
            <input placeholder={t("Home_Send")} />
           <Link to="/contact-us"> <button className="primary">{t("Home_SendC")}</button></Link>
          </form>
          <div className="social-links">
            <Link to="https://www.facebook.com/NCPDSA1" target="_blank">
            <FaFacebookF />
              {/* <img src="/assets/images/facebook.png" alt="facebook" /> */}
            </Link>
            <Link to="https://twitter.com/ncpd_sa" target="_blank">
              <FaXTwitter/>
              {/* <img src="/assets/images/twitter.png" alt="twitter" /> */}
            </Link>{" "}
            <Link to="https://www.linkedin.com/company/ncpdsa" target="_blank">
              <FaLinkedinIn/>
              {/* <img src="/assets/images/linkedin.png" alt="linkedin" /> */}
            </Link>
            <Link to="https://www.youtube.com/channel/UCHiSuxUQ-UvAoAijCtxqBCA" target="_blank">
              <FaYoutube/>
              {/* <img src="/assets/images/linkedin.png" alt="linkedin" /> */}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
