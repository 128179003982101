import React from "react";
import { Link } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";
import Accordion from "../../Components/Accordion/Accordion";
import "./media.css";
import { useTranslation } from "react-i18next";

const FAQs = () => {
  const {t} = useTranslation();
  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/"><h3>{t("Home")}</h3></Link>
          <FaAnglesRight size={14} />
          <Link to="/media-center/faqs"><h3>{t("mediaCenter")}</h3></Link>
          <FaAnglesRight size={14} />
          <span>{t("FAQ")}</span>
        </div>
      </div>
      <div className="content media-faqs">
        <div className="container-wrapper">
          <h1 className="bold f-40 color-primary">{t("FAQ")}</h1>
          <p className="medium f-14 color-gray">
            {t("Enhance your cultural ability by learning more news, practical articles and marketing moment by moment")}
          </p>
          <ul>
            {/* {data.map((item) => ( */}
              <li>
                <Accordion question={t("Question1")} answer={t("Answer1")} />
              </li>
              <li>
                <Accordion question={t("Question2")} answer={t("Answer2")} />
              </li>
              <li>
                <Accordion question={t("Question3")} answer={t("Answer3")} />
              </li>
              <li>
                {/* <Accordion question={t("Question4")} answer={t("Answer4")} /> */}
              </li>
              <li>
                <Accordion question={t("Question5")} answer={t("Answer5")} />
              </li>
              <li>
                <Accordion question={t("Question6")} answer={t("Answer6")} />
              </li>
              <li>
                <Accordion question={t("Question7")} answer={t("Answer7")} />
              </li>
            {/* ))} */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
