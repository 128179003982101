import React, { useState } from "react";
import { FaAnglesRight } from "react-icons/fa6";
import { BsPhone, BsTelephone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { Link } from "react-router-dom";
import "./contactus.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter, FaLinkedinIn, FaYoutube } from "react-icons/fa6";

const ContactUS = () => {
  const { t } = useTranslation();
  const notify = () => toast("Form submitted successfully.");
  const [inputValue, setInputValue] = useState({
    firstName: "",
    lastName: "",
    subject: "",
    phone: "",
    city: "",
    message: "",
    email: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = [
      "firstName",
      "lastName",
      "subject",
      "email",
      "phone",
      "city",
      "message",
    ];
    const isFormValid = requiredFields.every(
      (field) => inputValue[field].trim() !== ""
    );

    if (!isFormValid) {
      toast.error("Please fill in all the required fields.");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const phoneRegex = /^\d{8,15}$/

    if (!emailRegex.test(inputValue.email)) {
      toast.error("Email is not valid.");
      return;
    }
    if (!phoneRegex.test(inputValue.phone)) {
      toast.error("Phone number is not valid.");
      return;
    }
    
    notify();
    
  };
  const handleChange = (e) => {
    // console.log("handleChange called")
    e.preventDefault();
    setInputValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/">
            <h3>{t("Home")}</h3>
          </Link>
          <FaAnglesRight size={14} />
          <span>{t("Contact US")}</span>
        </div>
      </div>
      <div className="content contactus">
        <div className="container-wrapper">
          <h1 className="bold f-40 color-primary">{t("Contact US")}</h1>
          <section>
            <div className="get-in-touch">
              <h2 className="color-primary medium f-24">{t("Get in Touch")}</h2>
              <p className="color-gray medium f-14">
                {t(
                  "If you have opinions or comments on the service, proposals for development, or complaints related to our services, please do not hesitate to send them directly to the 'Customer Care Unit' through one of the following means of communication:"
                )}
              </p>
              <ul>
                <li>
                  <h4 className="color-primary medium f-16">{t("Phone")}</h4>
                  <Link to="tel:8003010055">
                    <span className="medium f-14" style={{fontFamily:"Arial", fontWeight:"bold"}}>
                      <BsTelephone size={16} />
                      8003010055
                    </span>
                  </Link>
                </li>
                <li>
                  <h4 className="color-primary medium f-16">{t("Email")}</h4>
                  <Link to="mailto:info@ncpd.org.sa">
                    <span className="bold f-14">
                      <CiMail size={16} />
                      info@ncpd.org.sa
                    </span>
                  </Link>
                </li>
                <li>
                  <h4 className="color-primary medium f-16">{t("Socials")}</h4>
                  <div className="social-links">
                    <Link
                      to="https://www.facebook.com/NCPDSA1"
                      target="_blank"
                      style={iconStyle}
                    >
                      <FaFacebookF />
                    </Link>
                    <Link
                      to="https://twitter.com/ncpd_sa"
                      target="_blank"
                      style={iconStyle}
                    >
                      <FaXTwitter />
                    </Link>{" "}
                    <Link
                      to="https://www.linkedin.com/company/ncpdsa"
                      target="_blank"
                      style={iconStyle}
                    >
                      <FaLinkedinIn />
                    </Link>
                    <Link
                      to="https://www.youtube.com/channel/UCHiSuxUQ-UvAoAijCtxqBCA"
                      target="_blank"
                      style={iconStyle}
                    >
                      <FaYoutube />
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmit}>
              <h2 className="f-24 medium">{t("Communication Information")}</h2>
              <p className="medium f-14">
                {t(
                  "To ensure speedy handling of your comment or request, please write a detailed explanation in order for us to serve you."
                )}
              </p>
              <div className="input-list">
                <input
                  name="firstName"
                  placeholder={t("First Name")}
                  onChange={handleChange}
                  value={inputValue.firstName}
                />
                <input
                  name="lastName"
                  placeholder={t("Last Name")}
                  onChange={handleChange}
                  value={inputValue.lastName}
                />
                <input
                  name="phone"
                  type="number"
                  pattern="[0-9]{10,15}"
                  placeholder={t("Phone Number")}
                  onChange={handleChange}
                  value={inputValue.phone}
                />
                <input
                  name="email"
                  type="email"
                  placeholder={t("Email")}
                  onChange={handleChange}
                  value={inputValue.email}
                />
                <input
                  name="subject"
                  type="text"
                  placeholder={t("Subject")}
                  onChange={handleChange}
                  value={inputValue.subject}
                />
                <input
                  name="city"
                  placeholder={t("City")}
                  onChange={handleChange}
                  value={inputValue.city}
                />
              </div>
              <input
                name="message"
                type="text-area"
                row="5"
                column="5"
                placeholder={t("message")}
                onChange={handleChange}
                value={inputValue.message}
              />
              {/* <select placeholder="Message">
                <option value="Message">{t("message")}</option>
                </select>*/}
              <button className="primary" type="submit">
                {t("Home_Send")}
              </button>
              <p className="medium f-14">
                {t(
                  "By clicking the Contact us button you agree to our Privacy Policy terms"
                )}
              </p>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
};

const iconStyle = {
  color: "inherit",
  textDecoration: "none",
};

export default ContactUS;
