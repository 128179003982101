import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { GrLocation } from "react-icons/gr";
import { CiMail } from "react-icons/ci";
import { BsPhone, BsTelephone } from "react-icons/bs";
import "./partners.css";
import { Link } from "react-router-dom";

function ServiceProvider({ center }) {
  const { t } = useTranslation();
  const handleAddressClick = (address) => {
    const googleMapsLink = `https://www.google.com/maps/place/${encodeURIComponent(
      address
    )}`;
    return window.open(googleMapsLink, "_blank");
  };
  return (
    <div className="service-center-container">
      <div className="service-provider" key={center.id}>
        <div className="service-images">
          <img src={`/assets/images/${center.image}`} alt="service center" />
          <div className="service-first">
            <h3 className="medium f-14">{t("Services they provide")}</h3>
            <ul>
              <li className="medium f-14">
                {t(`${center.services.service1}`)}
              </li>
              <li className="medium f-14">
                {t(`${center.services.service2}`)}
              </li>
              <li className="medium f-14">
                {t(`${center.services.service3}`)}
              </li>
            </ul>
          </div>
        </div>
        <div className="provided-services">
          <label className="medium f-14">{t("Name")}</label>
          <h5 className="medium f-14">{t(`${center.centerName}`)}</h5>
          <label>{t("City")}</label>
          <h5>{t(`${center.contactInfo.city}`)}</h5>
          <Link to={`tel:${center.contactInfo.phone}`}>
            <BsTelephone size={16} color="green"/>{" "}
            <label className="medium f-14">{t("Phone")}</label>
            <span className="medium f-14">
              <h5 className="medium f-14">
                {t(`${center.contactInfo.phone}`)}
              </h5>
            </span>
          </Link>
          <Link to={`mailto:${center.contactInfo.email}`}>
            <label className="medium f-14">
              <CiMail size={16} color="green" /> {t("Email")}
            </label>
            <h5>{t(`${center.contactInfo.email}`)}</h5>
          </Link>

          <Link to={center.contactInfo.location}>
            <GrLocation size="14" color="green" />{" "}
            <label
              onDoubleClick={() =>
                handleAddressClick(center.contactInfo.location)
              }
              target="_blank"
            >
              {t("Google map")}
            </label>
          </Link>
          {/* <h5>{t(`${center.contactInfo.location}`)}</h5> */}
          <div className="service-second">
            <h4 className="medium f-14">{t("Services they provide")}</h4>
            <ul>
              <li className="medium f-14">
                {t(`${center.services.service4}`)}
              </li>
              <li className="medium f-14">
                {t(`${center.services.service5}`)}
              </li>
              <li className="medium f-14">
                {t(`${center.services.service6}`)}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceProvider;
