export const data = [
  // 1
  {
    name: "service_agriculture",
    image: "/assets/images/agrSub.png",
    type: "Farm",
    tabs: ["SUPPORT"],

    cardData: [
      {
        label: "Beneficiary Group",
        value: "Small palm farmers with limited income.",
      },
      {
        label: "Service Delivery Channels",
        value: "e-Farmer Platform",
      },
      { label: "Language", value: "Arabic" },
      { label: "Service Application:", value: "https://e-farmer.ncpd.gov.sa" },
    ],
    introduction: {
      title: "Introduction",
      heading1: "Service Overview:",
      content: "service1.introduction_content",
    },
    conditions: {
      title: "Conditions and Required Documents:",
      cond1: "service1.conditions_cond1",
      cond2: "service1.conditions_cond2",
      cond3: "service1.conditions_cond3",
      cond4: "service1.conditions_cond4",
      cond5: "service1.conditions_cond5",
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading: "",
      content: "service1.beneficiary_content",
      image: "/assets/images/aboutus-2.png",
    },
    getService: {
      link: "https://e-farmer.ncpd.gov.sa",
      // content:
      //   "If you meet the criteria, benefit from this program to enhance date palm cultivation.",
      isClickTextRequired: false,
      data: [
        {
          title: "service1.data_title1",
          list: [
            "service1.data_listItem1",
            "service1.data_listItem2",
            "service1.data_listItem3",
            "service1.data_listItem4"
          ],
        }
      ],
    },
    relatedServices: [
      {
        title: "Date Palm Care Guide",
        url: "/services/Service_Date_Palm_Care_Guide",
      },
      {
        title: "Saudi Dates Farmer Mark",
        url: "/services/Service_Saudi_Dates_Mark_for_SFarmers",
      },
    ],
  },
  // 2
  {
    name: "Date_Purchase",
    image: "/assets/images/date_pur.png",
    type: "Farm",
    tabs: ["SUPPORT"],
    cardData: [
      { label: "Beneficiary Group", value: "Small date palm farmers." },
      {
        label: "Service Delivery Channels",
        value: "service2.value2",
      },
      { label: "Provided Language", value: "Arabic" },
      {
        label: "Service Application:",
        value: "//e-farmer.ncpd.gov.sa",
      },
    ],
    introduction: {
      title: "Introduction",
      heading1: "",
      content: "service2.introduction_content",
    },
    // conditions: {
    //   title: "Conditions and Required Documents:",
    //   cond1:
    //     "The applicant must be a Saudi national and not younger than 21 years old.",
    //   cond2: "The preferred candidate must be the owner or tenant of the farm.",
    //   cond3: "service2.conditions_cond3",
    //   cond4: "service2.conditions_cond4",
    // },
    conditionInSteps:{
      link: "https://saudidatesmark.com",
      isClickTextRequired: false,
      data: [
        {
          title: "The service focuses on the following standards:",
          list: [
            "Agricultural processes",
            "Water quality and soil management",
            "Health and occupational safety",
            "Pesticide control",
            "General cleanliness and agricultural waste management",
            "Safe storage",
            "Harvesting and processing",
          ],
        },
        {
          title:
            "Benefit from this program to enhance date palm cultivation and gain:",
          list: [
            "Distinction in public utility markets",
            "Priority in benefiting from the National Center for Palms and Dates programs",
            "Joining the Loyalty Program (Zarea) that provides benefits and discounts.",
          ],
        },
      ],
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading: "",
      content: "service2.beneficiary_content",
      image: "/assets/images/aboutus-2.png",
    },
    getService: {
      link: "//e-farmer.ncpd.gov.sa",
      content:
        "If you meet the criteria, benefit from this program to enhance date palm cultivation.",
      isClickTextRequired: true,
    },
    relatedServices: [
      {
        title: "Date Palm Care Guide",
        url: "/services/Service_Date_Palm_Care_Guide",
      },
      {
        title: "Saudi Dates Mark for Farmers",
        url: "/services/Service_Saudi_Dates_Mark_for_SFarmers",
      },
      {
        title: "Agricultural Support",
        url: "/services/service_agriculture",
      },
    ],
  },
  // 3
  {
    name: "Service_Saudi_Dates_Mark_for_SFarmers",
    type: "Farm",
    image: "/assets/images/sdmf.png",
    tabs: ["SUPPORT", "KNOWLEDGE"],
    cardData: [
      {
        label: "Beneficiary Group",
        value: "General date palm farmers and date producers",
      },
      {
        label: "Service Delivery Channels",
        value: "Saudi Dates Mark Platform.",
      },
      { label: "Language", value: "Arabic" },
      { label: "Service Application:", value: "https://saudidatesmark.com" },
    ],
    introduction: {
      title: "Introduction",
      heading1: "What is the Saudi Dates Mark?",
      content: "service3.introduction_content",
    },
    conditions: {
      title: "Conditions and Required Documents:",
      cond1: "service3.conditions_cond1",
      cond2: "service3.conditions_cond2",
      cond3: "service3.conditions_cond3",
      cond4: "service3.conditions_cond4",
      cond5: "service3.conditions_cond5",
      cond6: "service3.conditions_cond6",
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading: "General date palm farmers and date producers.",
      content: "",
      image: "/assets/images/aboutus-2.png",
    },
    getService: {
      link: "https://saudidatesmark.com",
      isClickTextRequired: false,
      data: [
        {
          title: "The service focuses on the following standards:",
          list: [
            "Agricultural processes",
            "Water quality and soil management",
            "Health and occupational safety",
            "Pesticide control",
            "General cleanliness and agricultural waste management",
            "Safe storage",
            "Harvesting and processing",
          ],
        },
        {
          title:
            "Benefit from this program to enhance date palm cultivation and gain:",
          list: [
            "Distinction in public utility markets",
            "Priority in benefiting from the National Center for Palms and Dates programs",
            "Joining the Loyalty Program (Zarea) that provides benefits and discounts.",
          ],
        },
      ],
    },
    relatedServices: [
      {
        title: "Date Palm Care Guide",
        url: "/services/Service_Date_Palm_Care_Guide",
      },
      {
        title: "Agricultural Subsidies",
        url: "/services/service_agriculture",
      },
      {
        title: "Date Purchase",
        url: "/services/Date_Purchase",
      },
    ],
  },
  // 4
  {
    name: "Service_Date_Palm_Care_Guide",
    image: "/assets/images/dpcg.png",

    type: "Farm",
    tabs: ["KNOWLEDGE"],
    cardData: [
      {
        label: "Beneficiary Group",
        value: "General date palm farmers and date producers",
      },
      {
        label: "Service Delivery Channels",
        value: "National Center for Palms and Dates website.",
      },
      { label: "Language", value: "Arabic" },
      {
        label: "Service Application:",
        value: "https://ncpd-website.vercel.app/e-library/Date",
      },
    ],
    introduction: {
      title: "Introduction",
      heading1: "Service Overview:",
      content: "service4.introduction_content",
    },
    conditions: {
      title: "Conditions and Required Documents:",
      cond1: "service4.conditions_cond1",
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading: "Farmers and farms in the Kingdom of Saudi Arabia.",
      content: " ",
      image: "/assets/images/aboutus-2.png",
    },
    relatedServices: [
      {
        title: "Agricultural Support Program",
        url: "/services/service_agriculture",
      },
      {
        title: "Saudi Dates Farmer Mark",
        url: "/services/Service_Saudi_Dates_Mark_for_SFarmers",
      },
      { title: "Date Purchase", url: "/services/Date_Purchase" },
    ],
    getService: {
      link: "https://ncpd-website.vercel.app/e-library/Date",
      isClickTextRequired: true,
    },
  },
  // 5
  {
    name: "Service_Zarea_Program",
    image: "/assets/images/zara_program.png",
    type: "Farm",
    tabs: ["SUPPORT", "KNOWLEDGE"],
    cardData: [
      {
        label: "Beneficiary Group",
        value: "Farmers holding the Saudi Dates Mark.",
      },
      {
        label: "Service Delivery Channels",
        value: "Saudi Dates Mark Platform.",
      },
      { label: "Language", value: "Arabic" },
      {
        label: "Service Application:",
        value: "https://saudidatesmark.com/planter-program",
      },
    ],
    introduction: {
      title: "Introduction",
      heading1: "What is the Zarea Program?",
      content: "service5.introduction_content",
    },
    conditions: {
      title: "Conditions and Required Documents:",
      cond1: "The beneficiary must hold the Saudi Dates Mark.",
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading: "Farmers holding the Saudi Dates Mark.", //done
      content: " ",
      image: "/assets/images/aboutus-2.png",
    },
    getService: {
      link: "https://saudidatesmark.com/user-login",
      content:
        "If you meet the criteria, benefit from this program to enhance date palm cultivation.",
      isClickTextRequired: true,
    },
    relatedServices: [
      {
        title: "Date Palm Care Guide",
        url: "/services/Service_Date_Palm_Care_Guide",
      },
      {
        title: "Saudi Dates Farmer Mark",
        url: "/services/Service_Saudi_Dates_Mark_for_SFarmers",
      },
    ],
  },
  // 6
  {
    name: "Service_Saudi_Dates_Mark_for_Factories",
    image: "/assets/images/sdmfactory.png",
    type: "Factory",
    tabs: ["SUPPORT", "KNOWLEDGE"],
    cardData: [
      { label: "Beneficiary Group", value: "Date Factories in Saudi Arabia." },
      {
        label: "Service Delivery Channels",
        value: "Saudi Dates Mark Platform.",
      },
      { label: "Language", value: "Arabic" },
      {
        label: "Service Application:",
        value: "https://saudidatesmark.com/planter-program",
      },
    ],
    introduction: {
      title: "Introduction",
      heading1: "What is the Saudi Dates Mark?",
      content: "service6.introduction_content",
    },
    conditions: {
      title: "Conditions and Required Documents:",
      cond1: "service6.conditions_cond1",
      cond2: "service6.conditions_cond2",
      cond3: "service6.conditions_cond3",
      cond4: "service6.conditions_cond4",
      cond5: "service6.conditions_cond5",
      cond6: "service6.conditions_cond6",
      cond7: "service6.conditions_cond7",
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading: "All date factories.",
      content: " ",
      image: "/assets/images/aboutus-2.png",
    },
    getService: {
      link: "https://saudidatesmark.com",
      isClickTextRequired: false,
      data: [
        {
          title: "The service is based on the following standards:",
          list: [
            "Planning (building planning and workspace).",
            "Facilities (water used, lighting, ventilation).",
            "Cleanliness, sterilization, waste management, and disposal",
            "Sanitary facilities - employee facilities.",
            "Transport and storage/product tracking.",
            "Purchased resource management/preventive measures.",
            "Product recall procedures/product information and consumer awareness.",
          ],
        },
        {
          title:
            "Benefit from this program to enhance the date industry and gain:",
          list: [
            "Assurance of food safety.",
            "Compliance with laws and regulations governing food factories.",
            "Increased profitability through cost savings.",
            "Awareness raising among workers in the food industries.",
            "Increased market value of products.",
            "Increased market value of Saudi dates in local and global markets.",
            "Increased Kingdom's share of exports of high-quality dates.",
          ],
        },
      ],
    },
    relatedServices: [
      {
        title: "Industrial Guidelines Manual",
        url: "/services/Service_Date_Palm_Care_Guide",
      },
    ],
  },
  // 7
  {
    name: "Services_Industrial_Guidelines_Manual_for_Dates",
    image: "/assets/images/indGuid_manualDates.png",
    type: "Factory",
    tabs: ["SUPPORT", "KNOWLEDGE"],
    cardData: [
      { label: "Beneficiary Group", value: "Date Factories in Saudi Arabia." },
      {
        label: "Service Delivery Channels",
        value: "National Center for Palm and Dates website",
      },
      { label: "Language", value: "Arabic" },
      {
        label: "Service Application:",
        value: "https://ncpd-website.vercel.app/e-library/Industrial",
      },
    ],
    introduction: {
      title: "Introduction",
      heading1: "Industrial Guidelines Manual for Food and Dates",
      content: "service7.introduction_content",
    },
    conditions: {
      title: "Conditions and Required Documents:",
      cond1: "service7.conditions_cond1",
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading:
        "Date factories and food factories engaged in date processing industries.",
      content: " ",
      image: "/assets/images/aboutus-2.png",
    },
    getService: {
      link: "https://ncpd-website.vercel.app/e-library/Date",
      content:
        "If you meet the criteria, benefit from this program to enhance the health and safety of the food and dates industry.",
      isClickTextRequired: true,
    },
    relatedServices: [
      {
        title: "Saudi Dates Mark for Factories.",
        url: "/services/Service_Saudi_Dates_Mark_for_Factories",
      },
    ],
  },
  // 8
  {
    name: "Services_Investment_Assistance_in_ServiceCenters",
    image: "/assets/images/investAssisServiceCenter.png",
    type: "Investor",
    tabs: ["SUPPORT"],
    cardData: [
      { label: "Beneficiary Group", value: "All Investors." },
      {
        label: "Service Delivery Channels",
        value: "National Center for Palm and Dates website.",
      },
      { label: "Language", value: "Arabic" },
      {
        label: "Service Application:",
        value: " https://ncpd-website.vercel.app/e-library/Investment",
      },
    ],
    introduction: {
      title: "Introduction",
      heading1: "Feasibility Studies:",
      content: "service8.introduction_content",
    },
    conditions: {
      title: "Conditions and Required Documents:",
      cond1:
        "These studies are designated and prepared for use in the Saudi market.",
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading: "service8.beneficiary_heading",
      content: " ",
      image: "/assets/images/aboutus-2.png",
    },
    getService: {
      link: "https://ncpd-website.vercel.app/e-library/",
      content: "Gain access to feasibility studies from the center's website.",
      isClickTextRequired: true,
    },
    relatedServices: [
      {
        title: "Palm Care Guide.",
        url: "/services/Service_Date_Palm_Care_Guide",
      },
      {
        title: "Saudi Dates Mark for Factories.",
        url: "/services/Service_Saudi_Dates_Mark_for_Factories",
      },
      {
        title: "Date Purchase.",
        url: "/services/Date_Purchase",
      },
    ],
  },
  // 9
  {
    name: "Services_Investment_Assistance_in_Processing_Industries",
    image: "/assets/images/investAssisProcessIndustries.png",

    type: "Investor",
    tabs: ["SUPPORT", "KNOWLEDGE"],
    cardData: [
      { label: "Beneficiary Group", value: "All Investors." },
      {
        label: "Service Delivery Channels",
        value: "National Center for Palm and Dates website.",
      },
      { label: "Language", value: "Arabic" },
      {
        label: "Service Application:",
        value: "https://ncpd-website.vercel.app/e-library/Investment",
      },
    ],
    introduction: {
      title: "Introduction",
      heading1: "Investment in Processing Industries:",
      content: "service9.introduction_content",
    },
    conditions: {
      title: "Conditions and Required Documents:",
      cond1:
        "These studies are designated and prepared for use in the Saudi market.",
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading:
        "Investors interested in operating in the palm and dates sector in the Kingdom of Saudi Arabia and its processing industries",
      content: " ",
      image: "/assets/images/aboutus-2.png",
    },

    getService: {
      link: "https://ncpd-website.vercel.app/e-library/Processing_Industries",
      content: "Review feasibility studies now.",
      isClickTextRequired: true,
    },
    relatedServices: [
      {
        title: "Date Palm Care Guide",
        url: "/services/Service_Date_Palm_Care_Guide",
      },
      {
        title: "Saudi Dates Mark for Farms.",
        url: "/services/Service_Saudi_Dates_Mark_for_SFarmers",
      },
      {
        title: "Date Purchase",
        url: "/services/Date_Purchase",
      },
    ],
  },
  // 10
  {
    name: "Services_Electronic_Palm_and_Date_Academy",
    image: "/assets/images/academy.png",
    type: "Farm",
    tabs: ["KNOWLEDGE"],
    cardData: [
      {
        label: "Beneficiary Group",
        value: "service10.value1",
      },
      {
        label: "Service Delivery Channels",
        value: "service10.value2",
      },
      { label: "Language", value: "Arabic" },
      { label: "Service Application:", value: "https://ma.ncpd.gov.sa" },
    ],
    introduction: {
      title: "Introduction",
      // heading1: "Feasibility Studies:",
      content: "service10.introduction_content",
    },
    conditions: {
      title: "Conditions and Required Documents:",
      cond1:
        "The applicant must be a Saudi national with a serious desire to work in this sector.",
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading: "Small palm farmers and beginners who own or lease farms.",
      content: " ",
      image: "/assets/images/aboutus-2.png",
    },
    getService: {
      link: "https://ma.ncpd.gov.sa",
      content:
        "If you meet the criteria, benefit from this program to enhance date palm cultivation.",
      isClickTextRequired: true,
    },
    relatedServices: [
      {
        title: "Palm Care Guide.",
        url: "/services/Service_Date_Palm_Care_Guide",
      },
      {
        title: "Saudi Dates Mark for Farmer",
        url: "/services/Service_Saudi_Dates_Mark_for_SFarmers",
      },
      {
        title: "Agricultural Aid Program",
        url: "/services/service_agriculture",
      },
    ],
  },
  // 11
  {
    name: "Services_Fast_Track",
    image: "/assets/images/fastTrack_outlet.png",

    type: "Investor",
    tabs: ["SUPPORT"],
    cardData: [
      {
        label: "Beneficiary Group",
        value: "Small palm farmers and beginners in agricultural work",
      },
      {
        label: "Service Delivery Channels",
        value: "National Center for Palms and Dates website.",
      },
      { label: "Language", value: "Arabic" },
      {
        label: "Service Application:",
        value:
          "https://ncpd.gov.sa/elnakhel/public/storage/omissives/18965967441612359975_%D9%86%D9%85%D9%88%D8%B0%D8%AC%20%D8%B7%D9%84%D8%A8%20%D8%A7%D9%95%D8%B5%D8%AF%D8%A7%D8%B1%20%D8%B4%D9%87%D8%A7%D8%AF%D8%A9%20%D8%B5%D8%AD%D9%8A%D8%A9%20%D9%84%D9%84%D8%AA%D9%85%D9%88%D8%B1.pdf",
        isPdf: true,
      },
    ],
    introduction: {
      title: "Introduction",
      heading1: "Description of the Service",
      content: "service11.introduction_content",
      image:
        "https://ncpd.gov.sa/elnakhel/public/storage/omissives/16157903091612360784_WhatsApp%20Image%202021-02-03%20at%204.58.55%20PM.jpeg",
    },
    conditions: {
      title: "Conditions and Required Documents:",
      // cond1: "The applicant must be affiliated with:",
      cond2: "Saudi Dates Mark.",
      cond3: "Organic Agriculture Certificate",
      cond4: "Saudi Good Agricultural Practices Certificate.",
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading: "Those holding quality certificates.",
      content: " ",
      image: "/assets/images/aboutus-2.png",
    },
    getService: {
      content:
        "If you meet the criteria, benefit from this program to expedite and facilitate date export operations.",
      isClickTextRequired: true,
      link: "https://ncpd.gov.sa/elnakhel/public/storage/omissives/18965967441612359975_%D9%86%D9%85%D9%88%D8%B0%D8%AC%20%D8%B7%D9%84%D8%A8%20%D8%A7%D9%95%D8%B5%D8%AF%D8%A7%D8%B1%20%D8%B4%D9%87%D8%A7%D8%AF%D8%A9%20%D8%B5%D8%AD%D9%8A%D8%A9%20%D9%84%D9%84%D8%AA%D9%85%D9%88%D8%B1.pdf",
    },
    relatedServices: [
      {
        title: "Saudi Dates Mark for Farms.",
        url: "/services/Service_Saudi_Dates_Mark_for_SFarmers",
      },
    ],
  },
  // 12
  {
    name: "Journey_to_Obtain_Industrial_License_at_Modon", // Journey to Obtain Industrial License at Modon
    image: "/assets/images/modon.png",

    type: "Investor",
    tabs: ["KNOWLEDGE"],
    cardData: [
      {
        label: "Beneficiary Group",
        value: "Factory who want to obtain an industrial license in Modon",
      },
      { label: "Service Delivery Channels", value: "Various entities" },
      { label: "Language", value: "Arabic" },
      // { label: "Service Application:", value: "https://ncpd.gov.sa/services/page/farm1/Health%20certificate" },
    ],
    introduction: {
      title: "Introduction",
      heading1: "Service Description:",
      content: "service12.introduction_content",
    },
    steps: [
      {
        title:
          "Issue a Commercial Register from the Ministry of Commerce - Saudi Arabia:",
        content:
          "You can issue a commercial register through the Ministry of Commerce's portal. For more information, please visit the mentioned link.",
        url: "https://mci.gov.sa",
      },
      {
        title:
          "Issue Approval for a Ready Building from the Saudi Authority for Industrial cities and Technology Zones (MODON):", //2
        content:
          "You can issue 'Approval for a Ready Building' through the Saudi Authority for Industrial cities and Technology Zones. For more information, please visit the mentioned link.",
        url: "https://modon.gov.sa",
      },
      {
        title:
          "Issue Preliminary Industrial License from the Ministry of Industry and Mineral Resources:",
        content:
          "You can issue a 'Preliminary Industrial License' from the Ministry of Industry and Mineral Resources through the website. For more information, please visit the mentioned link.",
        url: "https://mim.gov.sa",
      },
      {
        title: "Issue Safety License from the Civil Defense:", // 4
        content:
          "You can issue a 'Safety License' through the Civil Defense. For more information, please visit the mentioned link",
        url: "https://www.998.gov.sa",
      },
      {
        title:
          "Issue Environmental License from the General Authority for Meteorology and Environmental Protection:", //5
        content:
          "You can issue an 'Environmental License' from the General Authority for Meteorology and Environmental Protection. For more information, please visit the mentioned link.",
        url: "https://www.pme.gov.sa",
      },
      {
        title:
          "Issue Final Operational License from the Ministry of Industry and Mineral Resources:", //6
        content:
          "You can issue the 'Final Operational License from the Ministry of Industry and Mineral Resources. For more information, please visit the mentioned link.",
        url: "https://mim.gov.sa",
      },
      {
        title:
          "Issue Final Industrial License from the Ministry of Industry and Mineral Resources:", //7
        content:
          "You can issue the 'Final Industrial License' from the Ministry of Industry and Mineral Resources. For more information, please visit the mentioned link.",
        url: "https://mim.gov.sa",
      },
      {
        title:
          "Issue Activity License from the Saudi Food and Drug Authority (SFDA):", //8
        content:
          "You can issue an 'Activity License' through the Saudi Food and Drug Authority. For more information, please visit the mentioned link.",
        url: "https://www.sfda.gov.sa",
      },
    ],
    beneficiary: {
      title: "Target Beneficiary",
      heading:
        "Entrepreneurs, small and medium-sized enterprises, and all individuals seeking to obtain an industrial license in Modon.",
      content: " ",
      image: "/assets/images/aboutus-2.png",
    },
    // relatedServices: [
    //   {
    //     title: "",
    //     url: "",
    //   },
    // ],
  },
  // 13
  {
    name: "Municipal_Sector",
    image: "/assets/images/journey_municipalSector.png",
    type: "Investor",
    tabs: ["KNOWLEDGE"],
    cardData: [
      {
        label: "Beneficiary Group",
        value:
          "Individuals and companies interested in obtaining a final industrial license within the municipal sector.",
      },
      {
        label: "Service Channels",
        value: "Various entities",
        // links: [
        //   {
        //     title: "Ministry of Commerce",
        //     link: "https://mci.gov.sa",
        //   },
        //   {
        //     title: "Balady Portal",
        //     link: "balady.gov.sa",
        //   },
        //   {
        //     title: "Civil Defense",
        //     link: "https://www.998.gov.sa",
        //   },
        //   {
        //     title: "Ministry of Industry and Mineral Resources",
        //     link: "https://mim.gov.sa",
        //   },
        //   {
        //     title: "Ministry of Municipal and Rural Affairs and Housing",
        //     link: "https://www.momra.gov.sa",
        //   },
        //   {
        //     title:
        //       "General Authority for Meteorology and Environmental Protection",
        //     link: "https://www.pme.gov.sa",
        //   },
        //   {
        //     title: "Food and Drug Authority",
        //     link: "https://mci.gov.sa",
        //   },
        // ],
      },
      { label: "Language", value: "Arabic" },
    ],
    introduction: {
      title: "Introduction",
      heading1: "Description of the Service",
      content: "service13.introduction_content",
    },
    steps: [
      {
        title: "Issue a Commercial Register from the Ministry of Commerce:",
        content:
          "A commercial register can be issued through the Ministry of Commerce's portal",
        url: "https://mci.gov.sa",
      },
      {
        title: "Issue a Factory Construction License:",
        content:
          "A factory construction license can be issued through the Balady Portal",
        url: "balady.gov.sa",
      },
      {
        title: "Issue a No Objection Certificate from the Civil Defense:",
        content:
          "A no objection certificate can be issued from the Civil Defense through the General Directorate of Civil Defense's website",
        url: "https://www.998.gov.sa",
      },
      {
        title: "Issue Preliminary Industrial License:",
        content:
          "A preliminary industrial license can be issued through the Ministry of Industry and Mineral Resources",
        url: "https://mim.gov.sa",
      },
      {
        title: "Obtain Site Approval:",
        content:
          "Site approval can be obtained through the Ministry of Municipal and Rural Affairs and Housing",
        url: "https://www.momra.gov.sa",
      },
      {
        title: "Issue Safety License from the Civil Defense:",
        content:
          "A safety license can be issued through the General Directorate of Civil Defense's website",
        url: "https://www.998.gov.sa",
      },
      {
        title: "Issue Environmental License:",
        content:
          "An environmental license can be issued through the General Authority for Meteorology and Environmental Protection",
        url: "https://www.pme.gov.sa",
      },
      {
        title: "Issue Final Municipal License:",
        content:
          "A final municipal license can be issued through the Balady Portal",
        url: "balady.gov.sa",
      },
      {
        title: "Issue Final Industrial License:",
        content:
          "A final industrial license can be issued through the Ministry of Industry and Mineral Resources",
        url: "https://mim.gov.sa",
      },
      {
        title: "Issue Activity License:",
        content:
          "An activity license can be issued through the Food and Drug Authority",
        url: "https://www.sfda.gov.sa",
      },
    ],
  },
  // 14 Journey To Obtain Final Industrial License Within Agricultural Lands
  {
    name: "Journey_To_Obtain_Final_Industrial_License_Within_Agricultural_Lands",
    image: "/assets/images/journey_AgriLands.png",
    type: "Farm",
    tabs: ["KNOWLEDGE"],
    cardData: [
      {
        label: "Targeted Beneficiaries:",
        value: "Owners of industrial projects within agricultural lands.",
      },
      {
        label: "Service Channels ",
        value:
          "https://mci.gov.sa,\n https://balady.gov.sa, https://www.998.gov.sa, https://mim.gov.sa, https://www.momra.gov.sa, https://www.pme.gov.sa, https://balady.gov.sa, https://www.sfda.gov.sa",
      },
      { label: "Service Language:", value: "Arabic" },
      // { label: "Service Application:", value: "https://ncpd.gov.sa/services/page/farm1/Health%20certificate" },
    ],
    introduction: {
      title: "Introduction",
      heading1: "Service Description:",
      content: "service14.introduction_content",
    },
    steps: [
      {
        title:
          "Approval from the Ministry of Environment, Water, and Agriculture Branches:",
        content:
          "Obtain approval to establish the factory within agricultural lands by submitting an application to the branches of the Ministry of Environment, Water, and Agriculture according to the region.",
      },
      {
        title:
          "Issuing Approval from the Municipality to Convert Agricultural Land to Commercial:",
        content:
          "Obtain approval to convert agricultural land to commercial through the Municipality by submitting an application according to the region.",
      },
      {
        title:
          "Issuing Commercial Register from the Ministry of Commerce - Kingdom of Saudi Arabia",
        content:
          "Commercial register can be issued through the Ministry of Commerce Portal.",
        url: "https://mci.gov.sa",
      },
      {
        title:
          "Issuing Factory Construction License - Balady National Portal Supporting Municipal Community in Saudi Arabia:",
        content:
          "Issuing a factory construction license through Balady Portal.",
        url: "https://balady.gov.sa",
      },
      {
        title:
          "Issuing No Objection Certificate - General Directorate of Civil Defense:",
        content:
          "Possibility to issue a no objection certificate through Civil Defense.",
        url: "https://www.998.gov.sa",
      },
      {
        title:
          "Issuing Preliminary Industrial License - Ministry of Industry and Mineral Resources:",
        content:
          "Possibility to issue a preliminary industrial license through the Ministry of Industry and Mineral Resources.",
        url: "https://mim.gov.sa",
      },
      {
        title:
          "Issuing Site Approval - Ministry of Municipal and Rural Affairs", //7
        content:
          "Possibility to issue site approval through the Ministry of Municipal and Rural Affairs.",
        url: "https://www.momra.gov.sa",
      },
      {
        title: "Issuing Safety License - General Directorate of Civil Defense:",
        content: "Possibility to issue a safety license through Civil Defense.",
        url: "https://www.998.gov.sa",
      },
      {
        title: "Issuing Environmental License:", //9
        content:
          "Possibility to issue an environmental license through the General Authority for Meteorology and Environmental Protection.",
        url: "https://www.pme.gov.sa",
      },
      {
        title: "Issuing Final Municipal License - Balady Portal:", //10
        content:
          "Possibility to issue a final municipal license through Balady Portal.",
        url: "https://balady.gov.sa",
      },
      {
        title:
          "Issuing Final Industrial License - Ministry of Industry and Mineral Resources:",
        content:
          "Possibility to issue a final industrial license through the Ministry of Industry and Mineral Resources.",
        url: "https://mim.gov.sa",
      },
      {
        title: "Issuing Activity License - Food and Drug Authority:", // last step 12
        content:
          "Possibility to issue an activity license through the Food and Drug Authority.",
        url: "https://www.sfda.gov.sa",
      },
    ],
  },
  // 15
  {
    name: "Saudi_Dates_Sales_Platform",
    image: "/assets/images/saudi_salePlatform.png",
    type: "Inestor",
    tabs: ["SUPPORT", "KNOWLEDGE"],
    cardData: [
      {
        label: "Beneficiary Group",
        value:
          "International and local individual consumers seeking high-quality dates and their products bearing the Saudi Dates mark.",
      },
      {
        label: "Service Channels",
        value: "Specialized online platform for the sale of Saudi dates.",
      },
      { label: "Language", value: "Arabic and English" },
      { label: "Service Application:", value: "https://saudidates.sa" },
    ],
    introduction: {
      title: "Introduction",
      heading1: "Description of the Service",
      content: "service15.introduction_content",
    },
    conditions: {
      title: "Requirements and Documents:",
      cond1: "No specific requirements.",
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading:
        "Individual international and local consumers seeking high-quality dates.",
      content: " ",
      image: "/assets/images/aboutus-2.png",
    },
    getService: {
      content:
        "Take advantage of this platform to access high-quality and reliable products bearing the Saudi Dates mark.",
      isClickTextRequired: true,
      link: "https://saudidates.sa",
    },
  },
  // 16
  {
    name: "Services_Saudi_Dates_Wholesale_Platform",
    image: "/assets/images/saudi_wholesalePlatform.png",
    type: "Investor",
    tabs: ["KNOWLEDGE"],
    cardData: [
      {
        label: "Targeted Beneficiaries",
        value:
          "Global importers and local traders seeking high-quality dates in wholesale quantities.",
      },
      { label: "Language", value: "Arabic and English" },
      {
        label: "Service Channels:",
        value: "https://business.saudidates.sa",
      },
    ],
    introduction: {
      title: "Introduction",
      heading1: "Service Description:",
      content: "service16.introduction_content",
    },
    conditions: {
      title: "Requirements and Documents:",
      cond1: "No specific requirements.",
      cond2: "",
      cond3: "",
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading:
        "Global importers and local traders seeking high-quality dates in wholesale quantities.",
      content: " ",
      image: "/assets/images/aboutus-2.png",
    },
    getService: {
      content:
        "Take advantage of this platform to access high-quality and reliable wholesale date products bearing the Saudi Dates mark.",
      isClickTextRequired: true,
      link: "https://business.saudidates.sa",
    },
    relatedServices: [
      {
        title: "International Shipping Services for Dates",
        url: "/services/Services_Fast_Track", // link need to update
      },
      // {
      //   title: "Service_Saudi_Dates_Mark_for_Factories",
      //   url: "Service_Saudi_Dates_Mark_for_Factories", // link need to update
      // },
    ],
  },
  // 17
  {
    name: "Services_eFarmer_Platform",
    image: "/assets/images/eFormer.png",
    type: "Farm",
    tabs: ["SUPPORT", "KNOWLEDGE"],
    cardData: [
      {
        label: "Targeted Beneficiaries",
        value: "Farmers and individuals in the agricultural sector.",
      },
      { label: "Language", value: "Arabic" },
      { label: "Service Application:", value: "https://mouzare.ncpd.gov.sa/" },
    ],
    introduction: {
      title: "Introduction",
      heading1: "Description of the Service",
      content: "service17.introduction_content",
    },
    conditions: {
      title: "Requirements and Documents:",
      cond1: "The user must be a registered e-farmer on the platform.",
      cond2: "Provide national ID documents.",
      cond3: "Adhere to the terms and conditions specified on the platform.",
    },
    getService: {
      content:
        "Benefit from this platform to streamline the process of obtaining agricultural inputs and services, supporting the growth of your agricultural ventures.",
      isClickTextRequired: true,
      link: "https://mouzare.ncpd.gov.sa/",
    },
    relatedServices: [
      {
        title: "Saudi Dates Mark for Farmers",
        url: "/services/Service_Saudi_Dates_Mark_for_SFarmers",
      },
      {
        title: "Saudi Dates Wholesale Platform",
        url: "/services/Services_Saudi_Dates_Wholesale_Platform",
      },
      {
        title: "Saudi Dates Platform",
        url: "/platforms/saudi-dates-platform",
      },
    ],
  },
  // 18
  {
    name: "Services_NCPD_Digital_Library",
    image: "/assets/images/ncpdLib.png",
    type: "Factory",
    tabs: ["KNOWLEDGE"],
    cardData: [
      {
        label: "Target Beneficiary",
        value: "service18.value1",
      },
      { label: "Language", value: "Arabic and English" },
      {
        label: "Service Channels:",
        value: "https://ncpd-website.vercel.app/e-library",
      },
    ],
    introduction: {
      title: "Introduction",
      heading1: "Service Description:",
      content: "service18.introduction_content",
    },
    conditions: {
      title: "Available Content:",
      cond1:
        "Awareness and Guidance: Includes articles and practical tips for proper palm cultivation and date farming.",
      cond2:
        "Statistics and Research: Provides periodic reports and analytical studies on the date market's status and production trends.",
      cond3:
        "Trade and Market: Offers real-time reports on international date trade and investment opportunities.",
      cond4:
        "Technical Topics: Covers the latest content on agricultural technologies and technology in the palm industry.",
      cond5:
        "Sustainability and Scientific Research: A section addressing topics related to the sustainability of palm cultivation and scientific research in this context.",
      cond6:
        "Marketing and Export: Information on marketing and export strategies for date products.",
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading: "service18.beneficiary_heading",
      content: " ",
      image: "/assets/images/aboutus-2.png",
    },
    getService: {
      link: "https://ncpd-website.vercel.app/e-library/Processing_Industries",
      isClickTextRequired: true,
    },
    relatedServices: [
      {
        title: "Saudi Date Platform for Farmers",
        url: "/services/Service_Saudi_Dates_Mark_for_SFarmers",
      },
      {
        title: "Saudi Date Wholesale Platform",
        url: "/services/Services_Saudi_Dates_Wholesale_Platform",
      },
      {
        title: "Saudi Date Platform",
        url: "/services/Saudi_Dates_Sales_Platform",
      },
    ],
  },
  // 19
  {
    name: "Services_Mudrik_Platform",
    image: "/assets/images/mudrikPlatform.png",
    type: "Farm",
    tabs: ["KNOWLEDGE"],
    cardData: [
      {
        label: "Targeted Beneficiaries",
        value: "Online Mudrik Platform.",
      },
      { label: "Language", value: "Arabic" },
      { label: "Service Channels:", value: "NCPD Branches" },
    ],
    introduction: {
      title: "Introduction",
      heading1: "Description of the Service",
      content: "service19.introduction_content",
    },
    conditions: {
      title: "Requirements and Documents:",
      cond1:
        "The information should relate to the palm and date sector in the Kingdom of Saudi Arabia.",
    },
    beneficiary: {
      title: "Target Beneficiary",
      heading:
        "Internal communication with the National Center for Palms and Dates.",
      content: "",
      image: "/assets/images/aboutus-2.png",
    },
    objectives: [
      "Support and Development of Decision-Makers: Provide necessary information and analyses to support the decision-making process and enhance the development of the palm and date sector.",
      "Centralized Sector Data: Offer an efficient means to gather all data related to the palm and date sector in one easily accessible platform.",
      "Interactive Smart Data Presentation: Present data in an interactive and intelligent manner that facilitates the user's understanding of the current situation and guides efforts toward performance improvement.",
    ],
    getService: {
      link: "https://ncpd-website.vercel.app/contact-us/",
      isClickTextRequired: true,
    },
    sections: [
      {
        content:
          "Agricultural Data: Includes information about palm cultivation and management, such as types of palms, cultivation methods, and utilized technologies.",
      },
      {
        content:
          "Industrial Data: Encompasses information about the industry of products related to dates and the transformations in this sector.",
      },
      {
        content:
          "Local Data: Provides information about the local market, challenges, and opportunities that may impact the palm and date sector at the local level.",
      },
      {
        content:
          "International Data: Offers an overview of international developments in the palm and date sector and their impact on the local market.",
      },
    ],
  },
];

export const approvedCenter = [
  {
    id: "1",
    centerName: "approve1.centerName",
    image: "approve1.jpg",
    description: "approve1.description",
    contactInfo: {
      city: "Al-Qassim",
      phone: "0553556992",
      email: "info@alemtyaz.com.sa",
      location: "https://goo.gl/maps/Kxg1oHMS4yLTfAdY8",
    },
    services: {
      service1: "Cold storage",
      service2: "Sterilization",
      service3: "Purification and sorting",
      service4: "Graduation",
      service5: "Packaging",
      service6: "Preparing for export",
    },
  },
  {
    id: "2",
    centerName: "approve2.centerName",
    image: "approve2.jpg",
    description:"approve1.description",
    contactInfo: {
      city: "Al-Qassim",
      phone: "0509443418 - 0505922417 ",
      email: "thmratcoop.2020@outlook.com",
      location: "https://goo.gl/maps/5Faqq2aFfVFbVcoi9",
    },
    services: {
      service1: "Cold storage",
      service2: "Sterilization",
      service3: "Purification and sorting",
      service4: "Graduation",
      service5: "Packaging",
      service6: "Preparing for export",
    },
  },
  {
    id: "3",
    centerName: "approve3.centerName",
    image: "approve3.jpg",
    description:"approve3.description",
    contactInfo: {
      city: "Al-Madinah",
      phone: "0552030872",
      email: "medfac01@siafadates.com",
      location: "https://goo.gl/maps/c2YA1pdyiDWZbYHw6",
    },
    services: {
      service1: "Cold storage",
      service2: "Sterilization",
      service3: "Purification and sorting",
      service4: "Graduation",
      service5: "Packaging",
      service6: "Preparing for export",
    },
  },
  {
    id: "4",
    centerName: "approve4.centerName",
    image: "approve4.jpg",
    description:"approve4.description",
    contactInfo: {
      city: "Riyadh",
      phone: "0536700065",
      email: "dates.sales@leaha.com.sa",
      location: "https://goo.gl/maps/e62jCC9DzFhoA5Um8",
    },
    services: {
      service1: "Cold storage",
      service2: "Sterilization",
      service3: "Purification and sorting",
      service4: "Graduation",
      service5: "Packaging",
      service6: "Preparing for export",
    },
  },
  {
    id: "5",
    centerName: "approve5.centerName",
    image: "approve5.jpeg",
    description:"approve5.description",
    contactInfo: {
      city: "Al-Madinah",
      phone: "0555064696 ",
      email: "fcs@ataibah.com ",
      location: "https://goo.gl/maps/T3wai9YVKrp7KKUn7",
    },
    services: {
      service1: "Cold storage",
      service2: "Sterilization ",
      service3: "Purification and sorting ",
      service4: "Graduation ",
      service5: "Packaging ",
      service6: "Preparing for export",
    },
  },
  {
    id: "6",
    centerName: "approve6.centerName",
    image: "approve6.jpeg",
    description:"approve6.description",
    contactInfo: {
      city: "Al-Ahsa",
      phone: "0509443418 - 0505922417 ",
      email: "radco@alrafaya.com.sa ",
      location: "https://goo.gl/maps/TBNLNdWN5Phhy4Um8",
    },
    services: {
      service1: "Cold storage",
      service2: "Sterilization",
      service3: "Purification and sorting",
      service4: "Graduation",
      service5: "Packaging",
      service6: "Preparing for export",
    },
  },
];
