import React, { useState, useCallback, useEffect } from "react";
import {
  FaAnglesRight,
  FaChevronDown,
  FaChevronUp,
  FaCheck,
} from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import "./services.css";
import { Link } from "react-router-dom";
import { data } from "../../content/service";
import { useTranslation } from "react-i18next";

const Services = () => {
  const [selectedTab, setSelectedTab] = useState("ALL");
  const [open, setOpen] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [pageData, setPageData] = useState([]);
  const { t } = useTranslation();
  const selectedFilterHandler = useCallback(
    (filter) => {
      if (selectedFilter === filter) {
        setSelectedFilter("");
      } else {
        setSelectedFilter(filter);
      }
    },
    [selectedFilter]
  );

  useEffect(() => {
    dataHandler();
  }, [selectedFilter, selectedTab]);
  const dataHandler = useCallback(() => {
    let temp = [];
    if (selectedFilter) {
      if (selectedTab === "ALL") {
        temp = data.filter((item) => item.type === selectedFilter);
      } else if (selectedTab == "SERVICES") {
        temp = data.filter(
          (item) => item.type === selectedFilter && item.tabs.length === 2
        );
      } else {
        temp = data.filter(
          (item) =>
            item.type === selectedFilter &&
            item.tabs.indexOf(selectedTab) !== -1
        );
      }
    } else {
      if (selectedTab === "ALL") {
        temp = data;
      } else if (selectedTab == "SERVICES") {
        temp = data.filter((item) => item.tabs.length === 2);
      } else {
        temp = data.filter((item) => item.tabs.indexOf(selectedTab) !== -1);
      }
    }
    setPageData(temp);
  }, [selectedFilter, selectedTab]);

  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/"><h3>{t("Home")}</h3></Link>
          <FaAnglesRight size={14} />
          <span>{t("services")}</span>
        </div>
      </div>
      <div className="content services">
        <div className="container-wrapper">
          <h1 className="bold f-40 color-primary">{t("services")}</h1>
          <section>
            <div className="filters-section">
              <div className="headers">
                <span className="f-20 medium color-primary">{t("Filter")}</span>
                <button
                  onClick={() => setSelectedFilter("")}
                  className="bold f-16"
                >
                  <IoMdClose size={20} />
                </button>
              </div>
              <div className="filters">
                <h3 className={open ? "medium f-20 active" : "medium f-20"}>
                  {t("Our Services")}
                  {open ? (
                    <button onClick={() => setOpen(false)}>
                      <FaChevronUp size={14} />
                    </button>
                  ) : (
                    <button onClick={() => setOpen(true)}>
                      <FaChevronDown size={14} />
                    </button>
                  )}
                </h3>
                <ul className={open ? "active" : ""}>
                  <li>
                    <button onClick={() => selectedFilterHandler("Farm")}>
                      {selectedFilter === "Farm" ? <FaCheck size={14} /> : ""}
                    </button>
                    <span
                      className={
                        selectedFilter === "Farm" ? "bold f-14" : "medium f-14"
                      }
                    >
                      {t("Farm")}
                    </span>
                  </li>
                  <li>
                    <button onClick={() => selectedFilterHandler("Factory")}>
                      {selectedFilter === "Factory" ? (
                        <FaCheck size={14} />
                      ) : (
                        ""
                      )}
                    </button>
                    <span
                      className={
                        selectedFilter === "Factory"
                          ? "bold f-14"
                          : "medium f-14"
                      }
                    >
                      {t("Factory")}
                    </span>
                  </li>
                  <li>
                    <button onClick={() => selectedFilterHandler("Investor")}>
                      {selectedFilter === "Investor" ? (
                        <FaCheck size={14} />
                      ) : (
                        ""
                      )}
                    </button>
                    <span
                      className={
                        selectedFilter === "Investor"
                          ? "bold f-14"
                          : "medium f-14"
                      }
                    >
                      {t("Investor")}
                    </span>
                  </li>
                  {/* <li>
                    <button
                      onClick={() => selectedFilterHandler("Local markets")}
                    >
                      {selectedFilter === "Local markets" ? (
                        <FaCheck size={14} />
                      ) : (
                        ""
                      )}
                    </button>
                    <span
                      className={
                        selectedFilter === "Local markets"
                          ? "bold f-14"
                          : "medium f-14"
                      }
                    >
                      {t("Home_Local_markets")}
                    </span>
                  </li>
                  <li>
                    <button onClick={() => selectedFilterHandler("Export")}>
                      {selectedFilter === "Export" ? <FaCheck size={14} /> : ""}
                    </button>
                    <span
                      className={
                        selectedFilter === "Export"
                          ? "bold f-14"
                          : "medium f-14"
                      }
                    >
                      {t("Export")}
                    </span>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="details">
              <div className="tabs">
                <ul>
                  <li>
                    <button
                      className={
                        selectedTab === "ALL" ? "primary" : "primary outline"
                      }
                      onClick={() => setSelectedTab("ALL")}
                    >
                      {t("ALL")}
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        selectedTab === "SERVICES"
                          ? "primary"
                          : "primary outline"
                      }
                      onClick={() => setSelectedTab("SERVICES")}
                    >
                      {t("services")}
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        selectedTab === "SUPPORT"
                          ? "primary"
                          : "primary outline"
                      }
                      onClick={() => setSelectedTab("SUPPORT")}
                    >
                      {t("SUPPORT")}
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        selectedTab === "KNOWLEDGE"
                          ? "primary"
                          : "primary outline"
                      }
                      onClick={() => setSelectedTab("KNOWLEDGE")}
                    >
                      {t("KNOWLEDGE")}
                    </button>
                  </li>
                </ul>
                {selectedFilter && (
                  <span className="color-primary f-20 medium">
                    {t(selectedFilter)} ({pageData.length})
                  </span>
                )}
              </div>
              <ul className="cards">
                {pageData?.map((service) => (
                  <li>
                    <Link to={`/services/${service.name}`}>
                      <img src={service.image} alt={service.name} />
                      <span className="color-primary medium f-20 ">
                        {t(`${service.name}`)}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Services;
