import React from "react";
import { FaAnglesRight, FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import "./partners.css";
import { useTranslation } from "react-i18next";
import LaboratoriesTable from "./LaboratoriesTable";
import ApprovedCenters from "./ApproveCenters";
import FumigationTable from "./FumigationTable";
import { Link } from "react-router-dom";

const Partners = () => {
  const redirectToFugmentation = () => {
    window.open("https://ncpd.gov.sa/services/page/export/Fumigation");
  };
  const { t } = useTranslation();
  const data = [
    {
      name: "Partner_pre_harvest",
      services: [
        "Partner_Cultivation",
        "Partner_Plant",
        "Partner_Supervision",
        "Partner_pollination",
        "Partner_Pruning",
        "Partner_farm",
        "Partner_Slipping",
        "Partner_manual",
        "Partner_Mechanical",
        "Partner_Primary",
        "Partner_Cold_transport",
        "Partner_Temporary",
      ],
    },
    {
      name: "Partner_post_harvest",
      services: [
        "Partner_data",
        "Partner_Primary_cleaning",
        "Partner_Steaming",
        "Partner_drying",
        "Partner_Temporary_sto",
        "Partner_sorting",
        "Partner_Washing",
        "Partner_Grading",
        "Partner_Weighing",
        "Partner_Marketing",
      ],
    },
  ];
  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/">
            <h3>{t("Home")}</h3>
          </Link>
          <FaAnglesRight size={14} />
          <span>{t("Partners")}</span>
        </div>
      </div>
      <div className="content partners">
        <div className="container-wrapper">
          <h1 className="bold f-40 color-primary">{t("Financial_Partners")}</h1>
          {/* <p className="color-gray medium f-20">
            Lorem ipsum dolor sit amet consectetur. Donec lorem nulla.
          </p> */}
          <section className="partners-section">
            <div>
              <h2 className="bold f-28 color-primary">
                {/* {t("Partners_trusted")} */}
                {t("Financial Sector Partners")}
              </h2>
              {/* <p className="color-gray medium f-20">
                Lorem ipsum dolor sit amet consectetur.
                Donec lorem nulla.
              </p> */}
            </div>
            <ul>
              <Link to="/partners/sidf">
                <li>
                  <img src="/assets/images/partner1.png" alt="partner1" />
                </li>
              </Link>
              <Link to="/partners/saudiExim">
                <li>
                  <img src="/assets/images/partner2.png" alt="partner2" />
                </li>
              </Link>
              <Link to="/partners/monshaat">
                <li>
                  <img src="/assets/images/partner3.png" alt="partner3" />
                </li>
              </Link>
              <Link to="/partners/sdb">
                <li>
                  <img src="/assets/images/partner4.png" alt="partner4" />
                </li>
              </Link>
              <Link to="/partners/adf">
                <li>
                  <img src="/assets/images/partner5.png" alt="partner5" />
                </li>
              </Link>
              {/* <Link to="/partners/details/6">
                <li>
                  <img src="/assets/images/partner4.png" alt="partner4" />
                </li>
              </Link> */}
            </ul>
          </section>
          <section className="partner-services">
            <h2 className="bold f-28 color-primary">
              {t("Partners_dates_services")}
            </h2>
            <div className="details">
              <div className="info">
                <h3 className="bold f-20 color-primary">
                  {t("Partners_They_are_service")}
                </h3>
                <ul>
                  <li>
                    <p className="medium f-14 color-gray">
                      {t("Partners_dates_To_improve")}
                    </p>
                  </li>
                  <li>
                    <p className="medium f-14 color-gray">
                      {t("Partner_Raise_the")}
                    </p>
                  </li>
                  <li>
                    <p className="medium f-14 color-gray">
                      {t("Partner_Tprovide")}
                    </p>
                  </li>
                </ul>
                <p className="medium f-14 color-primary">
                  {t("Partner_In_addition")}
                  {/* In addition, the post-harvest services center works on
                  receiving farmers' dates and provides{" "}
                  <b className="bold">{t("Partner_sorting")}</b>,{" "}
                  <b className="bold">packing</b> and{" "}
                  <b className="bold">storage services</b> that help maintain
                  the quality of dates and raise Of market value. */}
                </p>
              </div>
              <div className="image-wrapper">
                <img src="/assets/images/dates.png" alt="dates" />
              </div>
            </div>
            <div className="services">
              {data.map((service, idx) => (
                <div className="service" key={idx}>
                  <h3 className="color-primary f-20 bold">
                    {t(`${service.name}`)}
                  </h3>
                  <ul>
                    {service.services.map((item, i) => (
                      <li>
                        <img src="/assets/images/check.png" alt={item} />
                        <span className="medium f-14 color-primary" key={i}>
                          {t(`${item}`)}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </section>
          <section className="approve-section">
            <ApprovedCenters />
          </section>
          <section className="laboratories">
            <div className="info">
              <h2 className="bold f-28 color-primary">
                {t("Partner_Laboratories")}
              </h2>
              <p className="medium f-20 color-gray">
                {t("Partner_In_addition")}
              </p>
              {/* <ul>
                <li>
                  <button className="primary outline">
                    <FaArrowLeft size={20} />
                  </button>
                </li>
                <li>
                  <button className="primary">
                    <FaArrowRight size={20} />
                  </button>
                </li>
              </ul> */}
            </div>
            <div className="image-wrapper">
              <img src="/assets/images/dates1.png" alt="dates1" />
            </div>
          </section>
          <section
            className="laboratories-table"
            style={{ marginBottom: "2rem" }}
          >
            <LaboratoriesTable />
          </section>
          {/* <section className="centers">
            <h2 className="bold f-28 color-primary">
              {t("Partner_Fumigation")}
            </h2>
          </section> */}
          <section
            className="laboratories"
            style={{ marginBottom: "2rem", marginTop: "2rem" }}
          >
            <div className="info">
              <h2 className="bold f-28 color-primary">
                {t("Partner_Fumigation")}
              </h2>
              <p className="medium f-16 color-gray">
                {t("fumigation_text")} <br />
                <br />
                {t("Countries that require date fumigation include:")}
                <br />
                <p className="medium f-14 color-gray">
                  {t("Republic of India")}
                  <br />
                  {t("Republic of Indonesia")}
                  <br />
                  {t("Some specific requests from importers")}
                  <br />
                </p>
              </p>
            </div>
            <div className="image-wrapper">
              <img src="/assets/images/fugimation.jpg" alt="dates1" />
            </div>
          </section>
          <section className="fumigation-table" style={{ marginTop: "2rem" }}>
            <FumigationTable />
          </section>
        </div>
      </div>
    </div>
  );
};

export default Partners;
