import React, { useEffect } from "react";
import { FaAnglesRight } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import "./platforms.css";
import { useTranslation } from "react-i18next";

const Platforms = () => {
  const path = useLocation().pathname;
  const { t } = useTranslation();
  // console.log("🚀 ~ file: Platforms.js:8 ~ Platforms ~ path:", path)

  const platformData = [
    {
      platformPath: "/platforms/e-farmer",
      title: "E-Farmer Platform",
      description: "Platform_farmers-market",
      serviceList: [
        "service_e_former1",
        "service_e_former2",
        "service_e_former3",
        "service_e_former4",
        "service_e_former5",
      ],
      subDescription: "e-farmerSub-description",
      image: "/assets/images/platform4.jpg",
      link: "https://e-farmer.ncpd.gov.sa/",
      buttonText: "Explore more",
    },
    {
      platformPath: "/platforms/saudi-dates-and-farms-mark",
      title: "Platforms_Saudi_dates_and_farms_mark_National",
      description: "Platforms_Saudi_dates_and_farms_mark_National_the_aim",
      image: "/assets/images/platform.png",
      link: "https://prize.ncpd.gov.sa/",
      buttonText: "Learn more",
    },
    {
      platformPath: "/platforms/zarea",
      title: "Platforms_Zarea_Saudi",
      description: "Platforms_Zarea_Saudi_this_platform",
      image: "/assets/images/platform2.png",
      link: "https://saudidatesmark.com/",
      buttonText: "Explore more",
    },
    {
      platformPath: "/platforms/e-farmer-platform",
      title: "Platform_e_Farmer_Electronic",
      description: "Platform_e_Farmer_Electronic_A_national",
      image: "/assets/images/platform3.png",
      link: "https://ma.ncpd.gov.sa/",
      buttonText: "Visit the academy",
    },
    {
      platformPath: "/platforms/mouzare-farms",
      title: "Platform_Mouzare",
      description: "Platform_Mouzare_this",
      image: "/assets/images/platform.png",
      link: "https://mouzare.ncpd.gov.sa/",
      buttonText: "Access the platform",
    },
    {
      platformPath: "/platforms/saudi-dates-platform",
      title: "Platform_Saudi_dates",
      description: "Platform_Saudi_dates_A_government",
      image: "/assets/images/platform5.webp",
      link: "https://saudidates.sa/",
      buttonText: "Discover Saudi Dates",
    },
    {
      platformPath: "/platforms/saudi-dates-for-business",
      title: "Platform_Saudi_dates_business",
      description: "Platform_Saudi_dates_business_A_digital",
      image: "/assets/images/platform6.png",
      link: "https://business.saudidates.sa/",
      buttonText: "Business platform",
    },
  ];
  const currentPlatform = platformData.find(
    (platform) => platform.platformPath === path.toString()
  );
  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/">
            <h3>{t("Home")}</h3>
          </Link>
          <FaAnglesRight size={14} />
          <span>{t("Platforms1")}</span>
        </div>
      </div>
      <div className="platforms content">
        <section className="container-wrapper">
          <ul>
            <li>
              <Link
                to="/platforms/e-farmer"
                className={
                  path === "/platforms/e-farmer"
                    ? "active f-24 medium color-primary"
                    : "medium f-24 color-gray"
                }
              >
                {t("e-Farmer Platform")}
              </Link>
            </li>
            <li>
              <Link
                to="/platforms/saudi-dates-and-farms-mark"
                className={
                  path === "/platforms/saudi-dates-and-farms-mark"
                    ? "active f-24 medium color-primary"
                    : "medium f-24 color-gray"
                }
              >
                {t("Platforms_Saudi_dates_and_farms_mark_National")}
              </Link>
            </li>
            <li>
              <Link
                to="/platforms/zarea"
                className={
                  path === "/platforms/zarea"
                    ? "active f-24 medium color-primary"
                    : "medium f-24 color-gray"
                }
              >
                {t("Platforms_Zarea_Saudi")}
              </Link>
            </li>
            <li>
              <Link
                to="/platforms/e-farmer-platform"
                className={
                  path === "/platforms/e-farmer-platform"
                    ? "active f-24 medium color-primary"
                    : "medium f-24 color-gray"
                }
              >
                {t("Platform_e_Farmer_Electronic")}
              </Link>
            </li>
            <li>
              <Link
                to="/platforms/mouzare-farms"
                className={
                  path === "/platforms/mouzare-farms"
                    ? "active f-24 medium color-primary"
                    : "medium f-24 color-gray"
                }
              >
                {t("Mouzare Platform")}
              </Link>
            </li>
            <li>
              <Link
                to="/platforms/saudi-dates-platform"
                className={
                  path === "/platforms/saudi-dates-platform"
                    ? "active f-24 medium color-primary"
                    : "medium f-24 color-gray"
                }
              >
                {t("Saudi Dates Platform")}
              </Link>
            </li>
            <li>
              <Link
                to="/platforms/saudi-dates-for-business"
                className={
                  path === "/platforms/saudi-dates-for-business"
                    ? "active f-24 medium color-primary"
                    : "medium f-24 color-gray"
                }
              >
                {t("Saudi Dates for Business")}
              </Link>
            </li>
          </ul>
          <div className="info">
            <h1 className="bold f-40 color-primary">
              {t(`${currentPlatform.title}`)}
            </h1>
            <p className="medium f-20 color-gray">
              {t(`${currentPlatform.description}`)}
            </p>
            {currentPlatform.serviceList &&
              currentPlatform.serviceList.map((item, idx) => (
                <div className="platformList">
                  <p className="medium f-20 color-gray" key={idx} style={{left:"20px"}}>
                    {t(`${item}`)}
                  </p>
                </div>
              ))}

            {currentPlatform.subDescription && (
              <p className="medium f-20 color-gray">
                {t(`${currentPlatform.subDescription}`)}
              </p>
            )}

            <Link
              to={currentPlatform.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="primary">
                {t(`${currentPlatform.buttonText}`)}
              </button>
            </Link>

            <div className="image-wrapper">
              <img
                src={
                  currentPlatform.image
                    ? currentPlatform.image
                    : "/assets/images/platform.png"
                }
                alt="platform"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Platforms;
