import React from "react";
import { FaAnglesRight } from "react-icons/fa6";
import "./aboutus.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AboutUS = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="breadcrumbs">
        <div className="container-wrapper">
          <Link to="/"><h3>{t("Home")}</h3></Link>
          <FaAnglesRight size={14} />
          <span>{t("About_Us")}</span>
        </div>
      </div>
      <div className="content about-us">
        <section className="section-1 container-wrapper">
          <h2 className="medium f-20 color-primary">{t("About_us")}</h2>
          <div>
            <h1 className="bold f-40 color-primary">{t("who_we_are")}</h1>
            <p className="medium f-20 color-secondary">
              {t("About_section-1")}
            </p>
            <p className="medium f-20 color-secondary">
              {t("About_us_The_National_Center_for_Palms_and_Dates")}
            </p>
          </div>
        </section>
        <section className="section-2 container-wrapper">
          <ul>
            <li>
              <div className="card">
                <h1 className="bold f-28 color-primary">
                  {t("About_us_Vision")}
                </h1>
                <p className="medium f-16 color-secondary">
                  {t("About_us_vision_To_be")}
                </p>
              </div>
              <div className="image-wrapper">
                {" "}
                <img src="/assets/images/aboutus-1.png" alt="aboutus-1" />
              </div>
            </li>
            <li>
              <div className="image-wrapper">
                <img src="/assets/images/aboutus-2.png" alt="aboutus-2" />
              </div>
              <div className="card">
                <h1 className="bold f-28 color-primary">
                  {t("About_us_Mission")}
                </h1>
                <p className="medium f-16 color-secondary">
                  {t("About_us_Mission_To_create")}
                </p>
              </div>
            </li>
          </ul>
        </section>
        <section className="section-3 container-wrapper">
          <h1 className="bold f-40 color-primary">{t("About_us_Strategic")}</h1>
          <p className="medium f-20 color-secondary">
            {t("About_us_Strategic_To_be_a_primary")}
            {/* <br /> */}
            {/* <br /> */}
          </p>
          <ul>
            <li>
              <span className="bold f-40" style={{fontFamily:"Arial", fontWeight:"bold"}}>01</span>
              <h3 className="bold f-20">{t("About_us_01")}</h3>
              <p className="f-16 medium">{t("About_us_01_Increase_the")}</p>
            </li>
            <li>
              <span className="bold f-40" style={{fontFamily:"Arial", fontWeight:"bold", color:"#1e495c"}}>02</span>
              <h3 className="bold f-20 color-primary">{t("About_us_02")}</h3>
              <p className="f-16 medium color-secondary">
                {t("About_us_02_Quality_Improve")}
              </p>
            </li>
            <li>
              <span className="bold f-40" style={{fontFamily:"Arial", fontWeight:"bold"}}>03</span>
              <h3 className="bold f-20">{t("About_us_03")}</h3>
              <p className="f-16 medium">{t("About_us_03_Consumption")}</p>
            </li>
            <li>
              <span className="bold f-40" style={{fontFamily:"Arial", fontWeight:"bold", color:"#1e495c"}}>04</span>
              <h3 className="bold f-20 color-primary">{t("About_us_04")}</h3>
              <p className="f-16 medium color-secondary">
                {t("About_us_04_Sustainability")}
              </p>
            </li>
          </ul>
        </section>
      </div>
      <section className="pillars">
        <div className="container-wrapper">
          <div>
            <h1 className="bold f-40 color-primary">{t("About_us_pillars")}</h1>
            <p className="medium f-20 color-primary">
              {t("About_us_pillars_Managing")}
            </p>
            <Link to="/contact-us">
            <button className="primary">{t("CONTACT_US")}</button>
            </Link>
          </div>
          <ul>
            <li>
              <img src="/assets/images/pillar1.png" alt="pillar1" />
              <h3 className="bold f-20 color-primary" style={{textAlign:"center"}}>
                {t("About_us_Institutional")}
              </h3>
            </li>
            <li>
              <img src="/assets/images/pillar2.png" alt="pillar2" />
              <h3 className="bold f-20 color-primary" style={{textAlign:"center"}}>
                {t("About_us_Services")}
              </h3>
            </li>
            <li>
              <img src="/assets/images/pillar3.png" alt="pillar3" />
              <h3 className="bold f-20 color-primary" style={{textAlign:"center"}}>
                {t("About_us_Marketing")}
              </h3>
            </li>
            <li>
              <img src="/assets/images/pillar4.png" alt="pillar4" />
              <h3 className="bold f-20 color-primary" style={{textAlign:"center"}}>
                {t("About_us_the_quality")}
              </h3>
            </li>
          </ul>
          <button className="mobile primary">{t("CONTACT_US")}</button>
        </div>
      </section>
    </div>
  );
};

export default AboutUS;
